/*
 * Called by <YearbookTPSClasses />
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const DisplayYbClassPages = ({allGrads, classPatches}) => {

  const [ classDataFromDB, setClassDataFromDB ] = useState([])

  useEffect( () => {
    axios.get(node_server + 'classLeaderAwardData')
      .then( res => {
        setClassDataFromDB(res.data.classData)
      } )
  }, [] )

  /* Make an array of TPS Classes */
  const classesWithClassPatch = classPatches.map(c => c.tpsClass.toLocaleLowerCase())

  /* Make an array of TPS Class Patch filenames */
  const classesWithClassPatchFilenames = classPatches.map(c => c.classPatchFilename)

  const displayAllPages = allGrads.map((gradArr) => {
    return gradArr.map((g, i) => {

      if (i === 0) {

        /* This filters through the data from ClassLeaderAndAwards data to see if there is
           a Class Leader listed or any award winners listed */
        const cd = classDataFromDB.filter( (c) => {
          if (c.tpsClass === g.tpsClass.toLocaleLowerCase()) {
            return c
          }
          return null
        } )

        let classData = {}

        /* If there was a record where a Class Leader or Award Winner exists, this
           puts it in the correct format for use below */
        if (cd.length > 0) {

          if (cd[0].abHonts !== undefined) {
            classData.abHonts = cd[0].abHonts.abHontsName
          }

          if (cd[0].cDot !== undefined) {
            classData.cDot = cd[0].cDot.cDotName
          }

          if (cd[0].classLeader !== undefined) {
            classData.classLeader = cd[0].classLeader.classLeaderName
          }

          if (cd[0].distinguishedAlumnus !== undefined) {
            classData.distinguishedAlumnus = cd[0].distinguishedAlumnus.distinguishedAlumnusName
          }

          if (cd[0].liethenTittle !== undefined) {
            classData.liethenTittle = cd[0].liethenTittle.liethenTittleName
          }

          if (cd[0].onizukaPropWash !== undefined) {
            classData.onizukaPropWash = cd[0].onizukaPropWash.onizukaPropWashName
          }

          if (cd[0].outstandingFTE !== undefined) {
            classData.outstandingFTE = cd[0].outstandingFTE.outstandingFTEName
          }

          if (cd[0].propWash !== undefined) {
            classData.propWash = cd[0].propWash.propWashName
          }

          if (cd[0].raymondJones !== undefined) {
            classData.raymondJones = cd[0].raymondJones.raymondJonesName
          }

        }

        return (
          <Grid container key={g.userId}>
            <Grid item xs={12}>
              <hr style={{color: 'darkgray', width: '100%', height: '5px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5'>Class {g.username.slice(g.username.lastIndexOf('.') + 1,g.username.length)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <hr style={{color: 'darkgray', width: '100%', height: '5px'}}/>
            </Grid>

            {/* Does this class have a class patch ?? */}
            {classesWithClassPatch.includes(g.tpsClass) && `${classesWithClassPatchFilenames[classesWithClassPatch.indexOf(g.tpsClass)]}`.startsWith('https://') ?
              <Grid container item xs={12} justifyContent='flex-end'>
                <img
                  alt='class patch'
                  src={`${classesWithClassPatchFilenames[classesWithClassPatch.indexOf(g.tpsClass)]}`} style={{height: '200px', width: '200px'}}/>
              </Grid>
              : null
            }

            {classesWithClassPatch.includes(g.tpsClass) && !`${classesWithClassPatchFilenames[classesWithClassPatch.indexOf(g.tpsClass)]}`.startsWith('https://') ?
              <Grid container item xs={12} justifyContent='flex-end'>
                <img
                  alt='class patch'
                  src={`/images/class-patches/${g.tpsClass}/${classesWithClassPatchFilenames[classesWithClassPatch.indexOf(g.tpsClass)]}`} style={{height: '200px', width: '200px'}}/>
              </Grid>
              : null
            }

            {/* Does this class have a Class Leader ?? */}
            {classData.classLeader === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start' style={{marginBottom: '1rem'}}>
                <Typography variant='h6'>
                  Class Leader: {classData.classLeader}
                </Typography>
              </Grid>
            }

            {/* Are there arny awards for this class ?? */}
            { classData.abHonts === undefined &&
              classData.cDot === undefined &&
              classData.classLeader === undefined &&
              classData.distinguishedAlumnus === undefined &&
              classData.liethenTittle === undefined &&
              classData.onizukaPropWash === undefined &&
              classData.outstandingFTE === undefined &&
              classData.propWash === undefined &&
              classData.raymondJones === undefined
              ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start' style={{marginBottom: '1rem'}}>
                <Typography variant='h6'>
                  AWARDS
                </Typography>
              </Grid>
            }

            {/* Does this class have a C-Dot Winner ?? */}
            {classData.cDot === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Aaron 'C-Dot' George: {classData.cDot}
                </Typography>
              </Grid>
            }

            {/* Does this class have a Liethen-Tittle Winner ?? */}
            {classData.liethenTittle === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Liethen-Tittle: {classData.liethenTittle}
                </Typography>
              </Grid>
            }

            {/* Does this class have an A.B. Honts Winner ?? */}
            {classData.abHonts === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  A.B. Honts: {classData.abHonts}
                </Typography>
              </Grid>
            }

            {/* Does this class have an Onizuka Prop Wash Winner ?? */}
            {classData.onizukaPropWash === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Onizuka Prop Wash: {classData.onizukaPropWash}
                </Typography>
              </Grid>
            }

            {/* Does this class have a Prop Wash Winner ?? */}
            {classData.propWash === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Prop Wash: {classData.propWash}
                </Typography>
              </Grid>
            }

            {/* Does this class have a Raymond L. Jones Winner ?? */}
            {classData.raymondJones === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Raymond L. Jones: {classData.raymondJones}
                </Typography>
              </Grid>
            }

            {/* Does this class have an Outstanding FTE Winner ?? */}
            {classData.outstandingFTE === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Outstanding FTE: {classData.outstandingFTE}
                </Typography>
              </Grid>
            }

            {/* Does this class have a Distinguished Alumnus Winner ?? */}
            {classData.distinguishedAlumnus === undefined ?
              null
              :
              <Grid container item xs={12} justifyContent='flex-start'>
                <Typography variant='h6'>
                  Distinguished Alumnus: {classData.distinguishedAlumnus}
                </Typography>
              </Grid>
            }

            <Grid container item xs={12} justifyContent='flex-start' style={{marginTop: '1rem'}}>
              <Typography variant='h6'>
                {i + 1}. {g.firstName} { g.lastName}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      return (
          <Grid container item xs={12} key={g.userId} justifyContent='flex-start'>
            <Typography variant='h6'>
              {i + 1}. {g.firstName} {g.lastName}
            </Typography>
          </Grid>
      )
    })
  })

  return (
    <Box>
      <Container>
        <Grid container>
          {displayAllPages}
        </Grid>
      </Container>
    </Box>
  )
}

DisplayYbClassPages.propTypes = {
  allGrads: PropTypes.array,
  classPatches: PropTypes.array
}

export default DisplayYbClassPages
