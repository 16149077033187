/*
 * <Search />
 *      <SearchByName />
 *      <SearchByNameDisplay />
 *      <PersonalAndClassStats />
 * 
 * The <PersonalAndClassState /> Component is hidden for a superUser
 */

import React, { useEffect, useState } from 'react'
import './SearchByName.css'
import axios from 'axios'
import { Box, Container, Grid } from '@material-ui/core'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import PersonalAndClassStats from './PersonalAndClassStats'
import SearchByNameDisplay from './SearchByNameDisplay'
import { store } from '../Redux/Store'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ViewYourClassPage from './ViewYourClassPage'

const SearchByName = props => {

   const { superUser } = store.getState()

   const [ allAlumniName, setAllAlumniName ] = useState('')
   const [ alumniArray, setAlumniArray ] = useState([])
   const [ alumniHasBio, setAlumniHasBio ] = useState([])
   const [ registeredAlumniName, setRegisteredAlumniName ] = useState('')
   const [ searchAllAlumniFocus, setSearchAllAlumniFocus ] = useState(false)
   const [ searchForAllAlumni, setSearchForAllAlumni ] = useState(-1)
   const [ searchRegisteredAlumniFocus, setSearchRegisteredAlumniFocus ] = useState(false)

   const handleSearchAllAlumni = (e) => {
      setSearchForAllAlumni(1)
      setAllAlumniName(e.target.value)
   }

   const handleSearchAllAlumniBlur = () => {
      setSearchRegisteredAlumniFocus(false)
   }

   const handleSearchAllAlumniFocus = () => {
      setRegisteredAlumniName('')
      setAlumniArray([])
      setSearchRegisteredAlumniFocus(true)
   }

   const handleSearchRegisterdAlumni = (e) => {
      setSearchForAllAlumni(0)
      setRegisteredAlumniName(e.target.value)
   }

   const handleSearchRegisteredAlumniBlur = () => {
      setSearchAllAlumniFocus(0)
   }

   const handleSearchRegisteredAlumniFocus = () => {
      setAllAlumniName('')
      setAlumniArray([])
      setSearchAllAlumniFocus(true)
   }

   /* useEffect only queries the DB when 3 or more characters have been entered for search */
   useEffect( () => {
      if( registeredAlumniName.length > 2 || allAlumniName.length > 2 ){
         
         /* 1st .then() pull data from res and pass to next .then() */
         /* 2nd .then() stores an array of alumni objects in local storage and also
            passes down to the 3rd .then() */
         /* 3rd .then() filters out only active grads as they are the only ones who
            can possibly have a Bio. Loops over array and passes an array of only userId
            down to the 4th .then() */
         /* 4th .then() checks the MongoDB to see which of the grads have a Bio and returns
            an array of objects [{userId: 10}, {userId: 2368}]. That array then strips out
            just the userId into another array */

         const alumniName = allAlumniName !== '' ? allAlumniName : registeredAlumniName

         axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
         axios.get( node_server + `alumni/${alumniName}/${searchForAllAlumni}`)
            .then( res => {
               return res.data
            })
            .then( data => {
               /* Object.values( data.alumniFound ) is:
               * [ {userId: 2368, username: michael.giebner.2002a , isActive: 1}, { ... }, { ... } ] */
               setAlumniArray( Object.values(data) )
               return Object.values(data)
            } )
            .then( data => {
               const activeGrads = data.filter( grad => {
                  if( grad.isActive ){ return grad.userId }
                  else{ return null }
               } )
               let activeGradIdArray = []
               for(let i = 0; i < activeGrads.length; i++){
                  activeGradIdArray.push(activeGrads[i].userId)
               }
               return activeGradIdArray
            } )
            .then( async activeGrads => {
               await axios.post(node_server + 'bio/bioExists', { activeGrads: activeGrads, CLIENT_PUBLIC_TOKEN })
               .then( data => {
                     const userIds = data.data.map( user => {
                        return user.userId
                     } )
                     setAlumniHasBio(userIds)
               } )
            } )
      }
   }, [allAlumniName, searchForAllAlumni, registeredAlumniName] )

   return (
      <Box className='search-by-name-box' >
         <Container >
            <Typography variant='h5' style={{marginTop: '1rem'}} >
               Search By Name
            </Typography>
            <Grid container direction='row' style={{marginTop: '0rem'}} >

               <Grid item xs={12} sm={6} style={{width: '50%', marginTop: '1rem'}} >
                  <TextField
                        name="search-registered-alumni"
                        label='Search Registered Alumni'
                        onChange={handleSearchRegisterdAlumni}
                        onBlur={handleSearchRegisteredAlumniBlur}
                        onFocus={handleSearchRegisteredAlumniFocus}
                        style={{width: '300px'}}
                        value={registeredAlumniName}
                        variant='outlined'
                  />
               </Grid>

               <Grid item xs={12} sm={6} style={{width: '50%', marginTop: '1rem'}} >
                  <TextField
                        name="search-all-alumni"
                        label='Search ALL Alumni'
                        onChange={handleSearchAllAlumni}
                        onBlur={handleSearchAllAlumniBlur}
                        onFocus={handleSearchAllAlumniFocus}
                        style={{width: '300px'}}
                        value={allAlumniName}
                        variant='outlined' 
                  />
               </Grid>
            </Grid>

            <SearchByNameDisplay alumniArray={alumniArray} alumniHasBio={alumniHasBio} searchForAllAlumni={searchForAllAlumni} />

            {searchAllAlumniFocus || searchRegisteredAlumniFocus || superUser ?
               null
               :
               <ViewYourClassPage />
            }

            {searchAllAlumniFocus || searchRegisteredAlumniFocus || superUser ?
               null
               :
               <PersonalAndClassStats />
            }

         </Container>
      </Box>
   )
}

export default SearchByName
