/*
 * Top level Page for Yearbook Admin, lots of similarities with <YearbookDisplayPage />
 */
import React, { useEffect, useState }     from 'react'
import axios                              from 'axios'
import Box                                from '@material-ui/core/Box'
import Container                          from '@material-ui/core/Container'
import AddYearbookPage                    from '../yb/AddYearbookPage'
import ClassLeaderAwardDataPage           from '../yb/ClassLeaderAwardDataPage'
import EditYearbookPage                   from '../yb/EditYearbookPage'
import Grid                               from '@material-ui/core/Grid'
import { node_server }                    from '../Helpers'
import ShowOneYearbookPage                from '../yb/ShowOneYearbookPage'
import Typography                         from '@material-ui/core/Typography'
import YearbookOptions                    from '../yb/YearbookOptions'
import YearbookTOC                        from '../yb/YearbookTOC'

const YearbookAdminPage = () => {

   const [ pageDataToEdit, setPageDataToEdit ] = useState({})
   const [ pageDataToShow, setPageDataToShow ] = useState({})
   const [ pageIndexToEdit, setPageIndexToEdit ] = useState(-1)
   const [ pageIndexToShow, setPageIndexToShow ] = useState(-1)
   const [ pages, setPages ] = useState([])
   const [ showAddYearbookPage, setShowAddYearbookPage ] = useState(false)
   const [ showClassLeaderAwardDataPage, setShowClassLeaderAwardDataPage ] = useState(false)
   const [ showEditYearbookOptions, setShowEditYearbookOptions ] = useState(true)
   const [ showEditYearbookPage, setShowEditYearbookPage ] = useState(false)
   const [ showAddYearbookTOCPage, setShowAddYearbooTOCPage ] = useState(false)
   const [ showOneYearbookPage, setShowOneYearbookPage ] = useState(false)

   /* Currently, this route returns _id, pageIndex, pageName and pageType only */
   useEffect(() => {
      axios.get(node_server + 'yearbook/getAllPages')
         .then( res => {
         setPages(res.data)
         })
   }, [])

   /* Get data for one Yearbook page */
   useEffect(() => {
      if (pageIndexToShow > -1) {
         axios.get(node_server + `yearbook/getOnePage/${pages[pageIndexToShow]._id}`)
         .then( res => {
         setPageDataToShow(res.data)
         // console.log('YearbookAdminPage data for one yearbook page is ', res.data)
         })
         .then(() => {
         setShowOneYearbookPage(true)
         })
      }

      if (pageIndexToEdit > -1) {
         axios.get(node_server + `yearbook/getOnePage/${pages[pageIndexToEdit]._id}`)
         .then( res => {
         setPageDataToEdit(res.data)
         // console.log('YearbookAdminPage data for one yearbook page is ', res.data)
         })
         .then(() => {
         setShowEditYearbookPage(true)
         })
      }
   }, [pageIndexToEdit, pageIndexToShow, pages])

   const cancelEdit = () => {
      setShowEditYearbookPage(false)
      setShowEditYearbookOptions(true)
   }

   const getIndexOfPageToEdit = (i) => {
      setPageIndexToShow(-1)
      setPageIndexToEdit(i)
      setShowAddYearbooTOCPage(false)
      setShowEditYearbookOptions(false)
   }

   const getIndexOfPageToShow = (i) => {
      setPageIndexToEdit(-1)
      setPageIndexToShow(i)
   }

   const getOption = (o) => {
      switch (o) {
         case 'Add New Yearbook Page':
         setShowAddYearbookPage(true)
         setShowAddYearbooTOCPage(false)
         setShowClassLeaderAwardDataPage(false)
         break
         case 'Class Leader / Award Data':
         setShowAddYearbookPage(false)
         setShowAddYearbooTOCPage(false)
         setShowClassLeaderAwardDataPage(true)
         break
         case 'See Current Pages':
         setShowAddYearbookPage(false)
         setShowClassLeaderAwardDataPage(false)
         setShowAddYearbooTOCPage(true)
         break
         default:
         break
      }
   }

   return (
      <Grid style={{marginTop: '6rem'}}>

         <Grid item xs={12}>
         <Typography variant='h4'>
            Yearbook Admin Page
         </Typography>
         </Grid>

         {showEditYearbookOptions ?
         <YearbookOptions getOption={getOption} />
         : null
         }

         {showAddYearbookPage ?
         <AddYearbookPage numPages={pages.length} />
         : null
         }

         {showAddYearbookTOCPage ?
         <YearbookTOC getIndexOfPageToEdit={getIndexOfPageToEdit} getIndexOfPageToShow={getIndexOfPageToShow} pageData={pages} />
         : null
         }

         {showOneYearbookPage ?
         <Box>
            <Container>
               <Grid>
               <ShowOneYearbookPage pageData={pageDataToShow} />
               </Grid>
            </Container>
         </Box>
         : null
         }

         {showEditYearbookPage ?
         <EditYearbookPage cancelEdit={cancelEdit} pageData={pageDataToEdit} topMargin={2} />
         : null
         }

         {showClassLeaderAwardDataPage ?
         <ClassLeaderAwardDataPage />
         :
         null
         }

      </Grid>
   )
}

export default YearbookAdminPage
