import { store } from '../Redux/Store'
import { allGradsAction } from '../Redux/Actions'
import axios from 'axios'

export const updateAfterDeleteGrad = (userId) => {
    const { allGrads } = store.getState()
    const updatedGrads = allGrads.filter( user => user.userId !== userId )
    store.dispatch(allGradsAction(updatedGrads))
}

export const getGradsByTpsClass = async (tpsClass) => {
    var searchByTPSClass = new FormData()

    searchByTPSClass.append("tpsClass", tpsClass)

    const dataFromPHP = await axios.post(get_grads_by_tps_class, searchByTPSClass)
        .then(res => res.data)
        .then(data => {
            store.dispatch(allGradsAction(data.allGrads))
            return data
        })
    return dataFromPHP
}

// Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
// This is no longer used. It has been replaced by getTPSClassesFromNodeServer().
// export const getTPSClasses = async () => {
//     return axios.post(get_classes_with_class_pages)
//     .then( res => res.data )
//     .then( data => {
//         return data.tpsClasses
//     })
// }

export const getTPSClassesFromNodeServer = async () => {
   axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
   return axios.get(node_server + 'classes')
   .then( res => res.data )
   .then( data => {
      /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
      after a GET was made to the Node server. */
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
      return data
   } )
}

export const bubbleSortArray = (array) => {
   /* Sort the array alphabetically */
   let aSwapWasMade = true
   const numRows = array.length
   let temp = ''

   while (aSwapWasMade) {
         aSwapWasMade = false
         for (var i = 0; i < numRows - 1; i++) {
            if (array[i] > array[i + 1]) {
               temp = array[i + 1]
               array[i + 1] = array[i]
               array[i] = temp
               aSwapWasMade = true
            }
         }
   }
   return array
}

export const bubbleSortArrayOfObjects = (array, sortBy) => {
   /* Sort the array of objects alphabetically by whatever is sent in sortBy */
   let aSwapWasMade = true
   const numRows = array.length
   let temp = ''

   while (aSwapWasMade) {
         aSwapWasMade = false
         for (var i = 0; i < numRows - 1; i++) {
            if (array[i][sortBy] > array[i + 1][sortBy]) {
               temp = array[i + 1]
               array[i + 1] = array[i]
               array[i] = temp
               aSwapWasMade = true
            }
         }
   }
   return array
}

export const charCount = 2400

export const CLIENT_PUBLIC_TOKEN = "TPS Alumni Site, 2002a Spartans"

///////////////////////////////////////////////////////////////////////////////
//                                                                           //
// FOR LOCAL DEVELOPMENT                                                     //
//                                                                           //
///////////////////////////////////////////////////////////////////////////////

// export const addGrad                                = "http://localhost:3001/addGrad.php"                               No longer used
// export const add_announcement                       = 'http://localhost:3001/add_announcement.php'
// export const deleteUser                             = "http://localhost:3001/deleteUser.php"
// export const delete_announcement                    = 'http://localhost:3001/delete_announcement.php'
// export const edit_announcement                      = 'http://localhost:3001/edit_announcement.php'
// export const forgot_username_password               = "http://localhost:3001/forgot_username_password.php"
// export const get_all_data_for_one_user              = "http://localhost:3001/get_all_data_for_one_user.php";            /Components/DeleteWarning.js
// export const get_all_grads_from_tbl_auth            = "http://localhost:3001/getAllGradsFromTblAuth.php";               /Components/PersonalAndClassStats/js via get_all_grads_from_tbl_auth
// export const get_announcements                      = 'http://localhost:3001/get_announcements.php'
// export const get_basic_analytics                    = 'http://localhost:3001/get_basic_analytics.php'
// export const get_bio_data_for_mongodb               = 'http://localhost:3001/GetBioDataForMongoDB.php'                  No longer used
// export const get_classes_with_class_pages           = 'http://localhost:3001/get_classes_with_class_pages.php'          No longer used
// export const get_grads_by_city_state                = "http://localhost:3001/get_grads_by_city_state.php"               No longer used
// export const get_grads_by_company                   = "http://localhost:3001/get_grads_by_company.php"                  /Components/SearchBoxTwo.js
// export const get_grads_by_current_job_title         = "http://localhost:3001/get_grads_by_current_job_title.php"        /Components/SearchBoxTwo.js
// export const get_grads_by_school                    = "http://localhost:3001/get_grads_by_school.php"
// export const get_grads_by_tps_class                 = "http://localhost:3001/get_grads_by_tps_class.php"
// export const get_grads_by_user_id                   = 'http://localhost:3001/get_grads_by_user_id.php'
// export const get_grads_by_user_id_from_tbl_auth     = 'http://localhost:3001/get_grads_by_user_id_from_tbl_auth.php'    No longer used
// export const get_notable_alumni                     = 'http://localhost:3001/get_notable_alumni.php'
// export const get_single_announcement                = "http://localhost:3001/get_single_announcement.php"
// export const get_single_bio                         = 'http://localhost:3001/get_single_bio.php'
// export const get_single_user                        = 'http://localhost:3001/get_single_user.php'
// export const login                                  = "http://localhost:3001/login.php"
// export const pathName                               = 'http://localhost:3001/'
// export const reset_password                         = "http://localhost:3001/resetPassword.php"
// export const search_by_city_state                   = "http://localhost:3001/search_by_city_state.php"
// export const search_for_current_companies           = "http://localhost:3001/search_for_current_companies.php"
// export const search_for_current_job_titles          = "http://localhost:3001/search_for_current_job_titles.php"
// export const search_for_schools_all                 = "http://localhost:3001/search_for_schools_all.php";
// export const search_for_tps_classes                 = "http://localhost:3001/search_for_tps_classes.php"
// export const three_month_lookback                   = 'http://localhost:3001/three_month_lookback.php'
// export const update_new_user                        = "http://localhost:3001/update_new_user.php"
// export const update_single_user                     = "http://localhost:3001/update_single_user.php"



/* Abandonded for node server */
// export const add_bio                                = 'http://localhost:3001/add_bio.php'
// export const add_class_page                         = 'http://localhost:3001/add_class_page.php'
// export const edit_one_bio                           = 'http://localhost:3001/edit_one_bio.php'
// export const get_bio_list                           = 'http://localhost:3001/get_bio_list.php'
// export const search_for_alumni_by_name              = "http://localhost:3001/search_for_alumni_by_name.php"


export const node_server                                 = process.env.NODE_ENV === 'development' ? "http://localhost:3002/"                                         : "https://quiet-sierra-11185.herokuapp.com/"


// export const addGrad                                     = process.env.NODE_ENV === 'development' ? "http://localhost:3001/addGrad.php"                              : "https://secret-lowlands-93624.herokuapp.com/addGrad.php";
export const deleteUser                                  = process.env.NODE_ENV === 'development' ? "http://localhost:3001/deleteUser.php"                           : "https://secret-lowlands-93624.herokuapp.com/deleteUser.php";
export const delete_announcement                         = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/delete_announcement.php'                  : "https://secret-lowlands-93624.herokuapp.com/delete_announcement.php";
export const edit_announcement                           = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/edit_announcement.php'                    : "https://secret-lowlands-93624.herokuapp.com/edit_announcement.php";
export const add_announcement                            = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/add_announcement.php'                     : "https://secret-lowlands-93624.herokuapp.com/add_announcement.php";
export const forgot_username_password                    = process.env.NODE_ENV === 'development' ? "http://localhost:3001/forgot_username_password.php"             : "https://secret-lowlands-93624.herokuapp.com/forgot_username_password.php";
export const get_all_data_for_one_user                   = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_all_data_for_one_user.php"            : "https://secret-lowlands-93624.herokuapp.com/get_all_data_for_one_user.php";
// export const get_all_grads_from_tbl_auth                 = process.env.NODE_ENV === 'development' ? "http://localhost:3001/getAllGradsFromTblAuth.php"               : "https://secret-lowlands-93624.herokuapp.com/getAllGradsFromTblAuth.php";
export const get_announcements                           = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_announcements.php'                    : "https://secret-lowlands-93624.herokuapp.com/get_announcements.php";
export const get_basic_analytics                         = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_basic_analytics.php'                  : "https://secret-lowlands-93624.herokuapp.com/get_basic_analytics.php";
// export const get_bio_data_for_mongodb                    = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/GetBioDataForMongoDB.php'                 : "https://secret-lowlands-93624.herokuapp.com/GetBioDataForMongoDB.php"
// export const get_classes_with_class_pages                = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_classes_with_class_pages.php'         : "https://secret-lowlands-93624.herokuapp.com/get_classes_with_class_pages.php";
export const get_grads_by_city_state                     = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_grads_by_city_state.php"              : "https://secret-lowlands-93624.herokuapp.com/get_grads_by_city_state.php";
// export const get_grads_by_company                        = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_grads_by_company.php"                 : "https://secret-lowlands-93624.herokuapp.com/get_grads_by_company.php";
export const get_grads_by_current_job_title              = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_grads_by_current_job_title.php"       : "https://secret-lowlands-93624.herokuapp.com/get_grads_by_current_job_title.php";
export const get_grads_by_school                         = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_grads_by_school.php"                  : "https://secret-lowlands-93624.herokuapp.com/get_grads_by_school.php"
export const get_grads_by_tps_class                      = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_grads_by_tps_class.php"               : "https://secret-lowlands-93624.herokuapp.com/get_grads_by_tps_class.php";
export const get_grads_by_user_id                        = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_grads_by_user_id.php'                 : "https://secret-lowlands-93624.herokuapp.com/get_grads_by_user_id.php";
export const get_grads_by_user_id_from_tbl_auth          = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_grads_by_user_id_from_tbl_auth.php'   : 'https://secret-lowlands-93624.herokuapp.com/get_grads_by_user_id_from_tbl_auth.php'
// export const get_notable_alumni                          = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_notable_alumni.php'                   : "https://secret-lowlands-93624.herokuapp.com/get_notable_alumni.php";
export const get_single_announcement                     = process.env.NODE_ENV === 'development' ? "http://localhost:3001/get_single_announcement.php"              : "https://secret-lowlands-93624.herokuapp.com/get_single_announcement.php";
// export const get_single_bio                              = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_single_bio.php'                       : "https://secret-lowlands-93624.herokuapp.com/get_single_bio.php";
// export const get_single_user                             = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/get_single_user.php'                      : "https://secret-lowlands-93624.herokuapp.com/get_single_user.php";
export const login                                       = process.env.NODE_ENV === 'development' ? "http://localhost:3001/login.php"                                : "https://secret-lowlands-93624.herokuapp.com/login.php";
export const pathName                                    = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/'                                         : "https://secret-lowlands-93624.herokuapp.com/";
// export const reset_password                              = process.env.NODE_ENV === 'development' ? "http://localhost:3001/resetPassword.php"                        : "https://secret-lowlands-93624.herokuapp.com/resetPassword.php";
export const search_by_city_state                        = process.env.NODE_ENV === 'development' ? "http://localhost:3001/search_by_city_state.php"                 : "https://secret-lowlands-93624.herokuapp.com/search_by_city_state.php";
export const search_for_current_companies                = process.env.NODE_ENV === 'development' ? "http://localhost:3001/search_for_current_companies.php"         : "https://secret-lowlands-93624.herokuapp.com/search_for_current_companies.php";
export const search_for_current_job_titles               = process.env.NODE_ENV === 'development' ? "http://localhost:3001/search_for_current_job_titles.php"        : "https://secret-lowlands-93624.herokuapp.com/search_for_current_job_titles.php";
export const search_for_schools_all                      = process.env.NODE_ENV === 'development' ? "http://localhost:3001/search_for_schools_all.php"               : "https://secret-lowlands-93624.herokuapp.com/search_for_schools_all.php";
export const search_for_tps_classes                      = process.env.NODE_ENV === 'development' ? "http://localhost:3001/search_for_tps_classes.php"               : "https://secret-lowlands-93624.herokuapp.com/search_for_tps_classes.php";
export const three_month_lookback                        = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/three_month_lookback.php'                 : 'https://secret-lowlands-93624.herokuapp.com/three_month_lookback.php'
// export const update_new_user                             = process.env.NODE_ENV === 'development' ? "http://localhost:3001/update_new_user.php"                      : "https://secret-lowlands-93624.herokuapp.com/update_new_user.php";
// export const update_single_user                          = process.env.NODE_ENV === 'development' ? "http://localhost:3001/update_single_user.php"                   : "https://secret-lowlands-93624.herokuapp.com/update_single_user.php";

// This is so the deployed site can't hit my local node-server if it is up and running
// export const node_server = "http://localhost:/"

///////////////////////////////////////////////////////////////////////////////
//                                                                           //
// FOR DEPLOYMENT                                                            //
//                                                                           //
///////////////////////////////////////////////////////////////////////////////




/* Abandonded for node server */
// export const add_bio                                = "https://secret-lowlands-93624.herokuapp.com/add_bio.php";
// export const add_class_page                         = "https://secret-lowlands-93624.herokuapp.com/add_class_page.php";
// export const edit_one_bio                           = "https://secret-lowlands-93624.herokuapp.com/edit_one_bio.php";
// export const get_bio_list                           = "https://secret-lowlands-93624.herokuapp.com/get_bio_list.php";
// export const search_for_alumni_by_name              = "https://secret-lowlands-93624.herokuapp.com/search_for_alumni_by_name.php";


///////////////////////////////////////////////////////////////////////////////
//                                                                           //
// LINKS                                                                     //
//                                                                           //
///////////////////////////////////////////////////////////////////////////////
export const LtGenDonaldPuttOfficialUSAFBio     = 'https://www.af.mil/About-Us/Biographies/Display/Article/105883/lieutenant-general-donald-leander-putt/'
export const MajGenAlbertBoydOfficialUSAFBio    = 'https://www.af.mil/About-Us/Biographies/Display/Article/107642/major-general-albert-boyd/'


/**********************/
/* DATA               */
/**********************/
export const awardYears = {
    abHonts: [
        '1956c', '1956d', '1957a', '1957c', '1957d', '1958a', '1958b', '1958c', '1959a', '1959b', '1959c',
        '1960a', '1960b', '1960c', '1961a', '1961c', '1962a', '1962c', '1963a', '1964a', '1964b', '1964c', '1965a', '1965b', '1965c'
    ],
    cDot: [
        '2001a', '2001b', '2002a', '2002b', '2003a', '2003b', '2004a', '2004b', '2005a', '2005b', '2006a', '2006b', '2007a', '2007b', '2008a', '2008b', '2009a', '2009b',
        '2010a', '2010b', '2011a', '2011b', '2012a', '2012b', '2013a', '2013b', '2014a', '2014b', '2015a', '2015b', '2016a', '2016b', '2017a', '2017b', '2018a', '2018b', '2019a', '2019b',
        '2020a', '2020b', '2021a', '2021b', '2022a', '2022b', '2023a', '2023b', '2024a', '2024b', '2025a', '2025b', '2026a', '2026b', '2027a', '2027b', '2028a', '2028b', '2029a', '2029b',
        '2030a', '2030b', '2031a', '2031b', '2032a', '2032b', '2033a', '2033b', '2034a', '2034b', '2035a', '2035b', '2036a', '2036b', '2037a', '2037b', '2038a', '2038b', '2039a', '2039b'
    ],
    distinguishedAlumnus: [
        '1982b', '1983a', '1983b', '1984a', '1984b', '1985a', '1985b', '1986a', '1986b', '1987a', '1987b', '1988a', '1988b', '1989a', '1989b',
        '1990a', '1990b', '1991a', '1991b', '1992a', '1992b', '1993a', '1993b', '1994a', '1994b', '1995a', '1995b', '1996a', '1996b', '1997a', '1997b', '1998a', '1998b', '1999a', '1999b',
        '2000a', '2000b', '2001a', '2001b', '2002a', '2002b', '2003a', '2003b', '2004a', '2004b', '2005a', '2005b', '2006a', '2006b', '2007a', '2007b', '2008a', '2008b', '2009a', '2009b',
        '2010a', '2010b', '2011a', '2011b', '2012a', '2012b', '2013a', '2013b', '2014a', '2014b', '2015a', '2015b', '2016a', '2016b', '2017a', '2017b', '2018a', '2018b', '2019a', '2019b',
        '2020a', '2020b', '2021a', '2021b', '2022a', '2022b', '2023a', '2023b', '2024a', '2024b', '2025a', '2025b', '2026a', '2026b', '2027a', '2027b', '2028a', '2028b', '2029a', '2029b',
        '2030a', '2030b', '2031a', '2031b', '2032a', '2032b', '2033a', '2033b', '2034a', '2034b', '2035a', '2035b', '2036a', '2036b', '2037a', '2037b', '2038a', '2038b', '2039a', '2039b'
    ],
    liethenTittle: [
        '1966a', '1966b', '1967a', '1967b', '1968a', '1968b', '1969a', '1969b',
        '1970a', '1970b', '1971a', '1971b', '1972a', '1972b', '1973a', '1974a', '1974b', '1975a', '1975b', '1976a', '1976b', '1977a', '1977b', '1978a', '1978b', '1979a', '1979b',
        '1980a', '1980b', '1981a', '1981b', '1982a', '1982b', '1983a', '1983b', '1984a', '1984b', '1985a', '1985b', '1986a', '1986b', '1987a', '1987b', '1988a', '1988b', '1989a', '1989b',
        '1990a', '1990b', '1991a', '1991b', '1992a', '1992b', '1993a', '1993b', '1994a', '1994b', '1995a', '1995b', '1996a', '1996b', '1997a', '1997b', '1998a', '1998b', '1999a', '1999b',
        '2000a', '2000b', '2001a', '2001b', '2002a', '2002b', '2003a', '2003b', '2004a', '2004b', '2005a', '2005b', '2006a', '2006b', '2007a', '2007b', '2008a', '2008b', '2009a', '2009b',
        '2010a', '2010b', '2011a', '2011b', '2012a', '2012b', '2013a', '2013b', '2014a', '2014b', '2015a', '2015b', '2016a', '2016b', '2017a', '2017b', '2018a', '2018b', '2019a', '2019b',
        '2020a', '2020b', '2021a', '2021b', '2022a', '2022b', '2023a', '2023b', '2024a', '2024b', '2025a', '2025b', '2026a', '2026b', '2027a', '2027b', '2028a', '2028b', '2029a', '2029b',
        '2030a', '2030b', '2031a', '2031b', '2032a', '2032b', '2033a', '2033b', '2034a', '2034b', '2035a', '2035b', '2036a', '2036b', '2037a', '2037b', '2038a', '2038b', '2039a', '2039b'
    ],
    onizukaPropWash: [
        '1987b', '1988a', '1988b', '1989a', '1989b',
        '1990a', '1990b', '1991a', '1991b', '1992a', '1992b', '1993a', '1993b', '1994a', '1994b', '1995a', '1995b', '1996a', '1996b', '1997a', '1997b', '1998a', '1998b', '1999a', '1999b',
        '2000a', '2000b', '2001a', '2001b', '2002a', '2002b', '2003a', '2003b', '2004a', '2004b', '2005a', '2005b', '2006a', '2006b', '2007a', '2007b', '2008a', '2008b', '2009a', '2009b',
        '2010a', '2010b', '2011a', '2011b', '2012a', '2012b', '2013a', '2013b', '2014a', '2014b', '2015a', '2015b', '2016a', '2016b', '2017a', '2017b', '2018a', '2018b', '2019a', '2019b',
        '2020a', '2020b', '2021a', '2021b', '2022a', '2022b', '2023a', '2023b', '2024a', '2024b', '2025a', '2025b', '2026a', '2026b', '2027a', '2027b', '2028a', '2028b', '2029a', '2029b',
        '2030a', '2030b', '2031a', '2031b', '2032a', '2032b', '2033a', '2033b', '2034a', '2034b', '2035a', '2035b', '2036a', '2036b', '2037a', '2037b', '2038a', '2038b', '2039a', '2039b'
    ],
    propWash: [
        '1956d', '1957a', '1957c', '1957d', '1958a', '1958b', '1958c', '1959a', '1959b', '1959c',
        '1960a', '1960b', '1960c', '1961a', '1961c', '1962a', '1962c',
        '1970b', '1971a', '1971b', '1972a', '1972b', '1973a', '1974a', '1974b', '1975a', '1975b', '1976a', '1976b', '1977a', '1977b', '1978a', '1978b', '1979a', '1979b',
        '1980a', '1980b', '1981a', '1981b', '1982a', '1982b', '1983a', '1983b', '1984a', '1984b', '1985a', '1985b', '1986a', '1986b', '1987a'
    ],
    outstandingFTE: [ '1973a', '1974a', '1974b', '1975a', '1975b', '1976a', '1976b', '1977a', '1977b', '1978a' ],
    raymondJones: [
        '1978b', '1979a', '1979b',
        '1980a', '1980b', '1981a', '1981b', '1982a', '1982b', '1983a', '1983b', '1984a', '1984b', '1985a', '1985b', '1986a', '1986b', '1987a', '1987b', '1988a', '1988b', '1989a', '1989b',
        '1990a', '1990b', '1991a', '1991b', '1992a', '1992b', '1993a', '1993b', '1994a', '1994b', '1995a', '1995b', '1996a', '1996b', '1997a', '1997b', '1998a', '1998b', '1999a', '1999b',
        '2000a', '2000b', '2001a', '2001b', '2002a', '2002b', '2003a', '2003b', '2004a', '2004b', '2005a', '2005b', '2006a', '2006b', '2007a', '2007b', '2008a', '2008b', '2009a', '2009b',
        '2010a', '2010b', '2011a', '2011b', '2012a', '2012b', '2013a', '2013b', '2014a', '2014b', '2015a', '2015b', '2016a', '2016b', '2017a', '2017b', '2018a', '2018b', '2019a', '2019b',
        '2020a', '2020b', '2021a', '2021b', '2022a', '2022b', '2023a', '2023b', '2024a', '2024b', '2025a', '2025b', '2026a', '2026b', '2027a', '2027b', '2028a', '2028b', '2029a', '2029b',
        '2030a', '2030b', '2031a', '2031b', '2032a', '2032b', '2033a', '2033b', '2034a', '2034b', '2035a', '2035b', '2036a', '2036b', '2037a', '2037b', '2038a', '2038b', '2039a', '2039b'
    ]
}