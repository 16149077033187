/* This page is called by <ManagePhotos />
 * <ManageClassPatches />
 *    <MySelect />
 *    <AddClassPatch />
 *    <Snackbar />
 *    <ShowClassPatchesToManage />
 *    <EditClassPatch />
*/
import React, { useEffect, useState }                          from 'react'
import AddClassPatch                                           from '../Components/AddClassPatch'
import AddEditPhotoFieldsComponent                             from '../Components/AddEditPhotoFieldsComponent'
import axios                                                   from 'axios'
import Box                                                     from '@material-ui/core/Box'
import ClassDropdown                                           from '../Components/ClassDropdown'
import Container                                               from '@material-ui/core/Container'
import EditClassPatch                                          from '../Components/EditClassPatch'
import { getTPSClassesFromNodeServer, node_server }            from '../Helpers'
import Grid                                                    from '@material-ui/core/Grid'
import ShowClassPatchesToManage                                from '../Components/ShowClassPatchesToManage'
import Snackbar                                                from '../Atoms/Snackbar'
import Typography                                              from '@material-ui/core/Typography'

const ManageClassPatches = props => {

   /* Item 1. Get list of TPS Classes from MySQL DB */
   const [ tpsClasses,              setTpsClasses              ] = useState([])

   /* Item 2. TPS class selected via <MySelect /> */
   const [ tpsClass,                setTpsClass                ] = useState('')

   /* Item 3. Get classPatch from MongoDB */
   const [ classPatch,              setClassPatch              ] = useState({})

   /* If a classPatch exists, display patch */
   const [ showClassPatch,          setShowClassPatch          ] = useState(false)
   /* Fetches data from the DB again */
   const [ fetchClassPatch,         setFetchClassPatch         ] = useState(false)

   const [ patchIdToEdit,           setPatchIdToEdit           ] = useState('')
   const [ patchToEdit,             setPatchToEdit             ] = useState('')
   const [ snackBarOpen,            setSnackBarOpen            ] = useState(false)
   const [ showEditPatch,           setShowEditPatch           ] = useState(false)
   const [ snackbarOpen,            setSnackbarOpen            ] = useState(false)

   /* 
   * Item 1.
   * This used to call the PHP server and gets a list of all the TPS Classes from tbl_class_page.
   * It now calls the Noder Server and does the same thing.
   */
   useEffect( () => {
      getTPSClassesFromNodeServer()
         .then( classes => setTpsClasses(classes) )
         .catch( msg => console.log('There was an error on get', msg) )
   }, [] )

   /* 
   * Item 2.
   * Sent down to <ClassDropdown /> which sends back a String containing the selected TPS Class
   */
   const getSelectedClass = (e) => {
      setTpsClass(e)
   }

   /* 
   * Item 3.
   * Take TPS Class from <MySelect /> and check the MongoDB to see if an entry exists or not
   */
   useEffect( () => {
      if( tpsClass !== '' ){
         axios.get(node_server + `classPatch/GetClassPatch/${tpsClass}`)
               .then( res => res.data )
               .then( data => {
                  if( data ){
                     setClassPatch( data )
                     setShowClassPatch(true)
                  }
                  else{
                     setShowClassPatch(false)
                     setSnackbarOpen(true)
                     setTimeout( () => {
                           setSnackbarOpen(false)
                     }, 2500 )
                  }
               } )
      }
   }, [tpsClass] )

   /*
   * Item 4.
   * Takes updated classPatch from <AddClassPatch /> and puts it in local state
   */
   const addClassPatchToLocalState = (classWithPatchAdded) => {
      setClassPatch(classWithPatchAdded)
      setShowClassPatch(true)
   }

   // Updates the classPatch in MongoDB
   const updateClassPatch = () => {
      axios.put(node_server + 'classPatch/UpdateClassPatch', {
         classPatch
      })
         .then( res => res.data )
         .then( data => {
               setClassPatch(data)
               setFetchClassPatch( !fetchClassPatch )
         })
   }

   // Sent to <ShowClassPhotoToManage /> to get id of photo to edit
   const getPatchIdToEdit = (id) => {
      setPatchIdToEdit(id)
      if (classPatch._id === id) {
         setPatchToEdit(classPatch)
      }
      else {
         setPatchToEdit(null)
      }
      /* This removes <ShowClassPatchToManage while EDIT is being performed */
      setShowClassPatch(false)
      /* This shows or hides <AddClassPatch /> and <EditClassPhoto /> */
      setShowEditPatch(true)
   }

   /* Send down to <EditClassPatch />, will send back updated data */
   const updatedDataForDB = (d) => {
      if (classPatch._id === patchIdToEdit) {
         classPatch.classPatchFilename = d.filename
         setClassPatch(classPatch)
      }
      else {
         setClassPatch(null)
      }
      updateClassPatch()
      setShowEditPatch(false)
      setShowClassPatch(true)
      return null
   }

   const handleSnackBar = () => {
      setSnackBarOpen(true)
      setTimeout(() => {
     }, 2500)
   }

   return (
      <Box style={{marginTop: '6rem'}} >
         <Typography variant='h3' >
               Manage Class Patches
         </Typography>

         <Container>
               <ClassDropdown tpsClasses={tpsClasses} getSelectedClass={getSelectedClass} />
         </Container>

         {!showEditPatch ?
            <AddClassPatch addClassPatchToLocalState={addClassPatchToLocalState} classPatch={classPatch} tpsClass={tpsClass} />
            :
            null
         }
         {snackbarOpen ?
         <Grid container item style={{marginTop: '2rem', marginLeft: '30%', width: '40%'}} >
               <Snackbar type='error' msg={'That class does not yet have a class patch in the Database'} />
         </Grid>
         : null}
         {showClassPatch ?
               <ShowClassPatchesToManage classPatch={classPatch} getPatchIdToEdit={getPatchIdToEdit} />
         : null}
         {showEditPatch ?
               <EditClassPatch classPatch={classPatch} patchToEdit={patchToEdit} updatedDataForDB={updatedDataForDB} />
         :   null}
         {snackBarOpen
            ?
               <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                  <Grid item sm={6}>
                        <Snackbar msg={'Your photo was added!'} type={'success'} />
                  </Grid>
               </Grid>
            :
            null
         }
         <AddEditPhotoFieldsComponent
            handleSnackBar={handleSnackBar}
            title={'Add Photo Page'}
         />
      </Box>
   )
}

export default ManageClassPatches
