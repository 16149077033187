/*
 * Top level component for Yearbook viewing, this is the page with the graphic that is a button
 * for entering the yearbook itself. Clicking the graphic leads t the YearbookDisplayPage which
 * has the Table of Contents. Each button on the page is linked to a dynamic page.
 */
import React from 'react'
import background from '../images/YearbookCover.png'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

const YearbookPage = () => {
  return (
    <Grid style={{marginTop: '6rem', width: '100%', height: '100%'}}>
      <Link to='/YearbookDisplayPage' style={{textDecoration: 'none', color: 'inherit'}}>
        <img
          alt='yearbook-collage'
          src={`${background}`}
          style={{width: '85%', height: '85%'}}
        />
      </Link>
    </Grid>
  )
}

export default YearbookPage
