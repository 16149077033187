/*
 * This is the second level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowGradsFromOneClass />
 *              <GradsForOneClass />
 *          <ShowJournalEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddJournalEntry />
 *          <EditJournalEntry />
 *          <ShowClassPhotos />
 */
import React, { useState }    from 'react'
import GradsForOneClass       from './GradsForOneClass'
import Grid                   from "@material-ui/core/Grid"
import PropTypes              from 'prop-types'
import Typography             from "@material-ui/core/Typography"

const ShowGradsFromOneClass = ({tpsClass}) => {

   const [grads, setGrads] = useState([])

   const getGradsForOneClass = (gradsForOneClass) => {
      setGrads(gradsForOneClass)
   }

   let gradsToDisplay = (<Grid container justifyContent='center' item xs={12} style={{ marginTop: '1rem' }} >
                           <Typography variant='h6' >
                              There was an error retrieving the data, please try a different class. If this error persists please
                              contact the site admin at admin@usaftpsalumni.com
                           </Typography>
                        </Grid>)

   /* If there is a class listed that has no grads in it (this has happened due to errors in the database)
      then show some error test rather than crashing the site by trying to map over null */
   if(grads){
      // Show grads for selected class
      gradsToDisplay = grads.map((grad, index) => {

         // Get first name
         const indexOfFirstDot = grad.indexOf('.')
         var firstName = grad.slice(0, indexOfFirstDot)
         var firstChar = firstName[0].toUpperCase()
         firstName = firstName.slice(1, indexOfFirstDot)
         firstName = firstChar + firstName

         // Get last name
         var indexOfLastDot = grad.lastIndexOf('.')
         var lastName = grad.slice(0, indexOfLastDot)
         indexOfLastDot = lastName.lastIndexOf('.')
         var lastNameLength = lastName.length
         lastName = lastName.slice(indexOfLastDot + 1, lastNameLength)
         firstChar = lastName[0].toUpperCase()
         lastName = lastName.slice(1, lastName.length)
         lastName = firstChar + lastName

         return (<Grid key={index} container justifyContent='center' item xs={6} style={{ marginTop: '1rem' }} >
               <Typography variant='h5' >
                  {firstName} {lastName}
               </Typography>
         </Grid>)
      })
   }

   return (
      <Grid container direction='row'>
         { gradsToDisplay }
         <GradsForOneClass getGradsForOneClass={getGradsForOneClass} tpsClass={tpsClass} />
      </Grid>
   )
}

ShowGradsFromOneClass.propTypes = {
    tpsClass: PropTypes.string
}

export default ShowGradsFromOneClass
