/*
 * This Component is called by <ClassPages />, <ManageClassPatches />, <ManageClassPhotos /> and <MoreInfo />
 */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import MySelect from "../Components/MySelect"
import PropTypes from 'prop-types'

const ClassDropdown = ({getSelectedClass, tpsClasses}) => {

   const onChange = (e) => {
      getSelectedClass(e.target.value)
   }
   
   return (
      <Grid data-testid='class-pages-dropdown'>
         <MySelect name="searchSelection" label="Select A Class" options={tpsClasses} onChange={onChange} />
      </Grid>
   )
}

ClassDropdown.propTypes = {
   getSelectedClass: PropTypes.func,
   tpsClasses: PropTypes.array
}

export default ClassDropdown
