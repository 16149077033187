/* This page is called by <ManagePhotos />
 * <ManageBioPhotos />
 *    <GetBioListPartial /> used to be <GetBioList />
 *    <MySelect />
 *    <AddBioPhoto />
 *    <Snackbar />
 *    <ShowBioPhotoToManage />
 *    <EditBioPhoto />
*/

import React, { useCallback, useEffect, useState }             from 'react'
import AddBioPhoto                                             from '../Components/AddBioPhoto'
import axios                                                   from 'axios'
import AddEditPhotoFieldsComponent                             from '../Components/AddEditPhotoFieldsComponent'
import Box                                                     from '@material-ui/core/Box'
import EditBioPhoto                                            from '../Components/EditBioPhoto'
import GetBioListPartial                                       from '../Components/GetBioListPartial'
import Grid                                                    from '@material-ui/core/Grid'
import MySelect                                                from "../Components/MySelect"
import { photoDims}                                            from '../config'
import { CLIENT_PUBLIC_TOKEN, node_server }                    from '../Helpers'
import ShowBioPhotoToManage                                    from '../Components/ShowBioPhotoToManage'
import Snackbar                                                from '../Atoms/Snackbar'
import Typography                                              from '@material-ui/core/Typography'

const ManageBioPhotos = () => {

   const [ bio,                     setBio                     ] = useState({})
   const [ bioList,                 setBioList                 ] = useState([])
   const [ bioPhoto,                setBioPhoto                ] = useState([])
   const [ photoIdToEdit,           setPhotoIdToEdit           ] = useState('')
   const [ photoToEdit,             setPhotoToEdit             ] = useState('')
   const [ userIdList,              setUserIdList              ] = useState([])
   const [ userIdSelected,          setUserIdSelected          ] = useState(-1)
   const [ showBioPhoto,            setShowBioPhoto            ] = useState(false)
   const [ showEditPhoto,           setShowEditPhoto           ] = useState(false)
   const [ snackbarOpen,            setSnackbarOpen            ] = useState(false)
   const [ snackBarOpen,            setSnackBarOpen            ] = useState(false)

   // Fetches data from the DB again
   const [ fetchBio, setFetchBio ] = useState(false)

   /* Once a bio is selected this will get that bio from the MongoDB */
   useEffect( () => {
      if( userIdSelected >= 0 ){
         axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
         axios.get(node_server + `bio/${userIdSelected}`)
               .then( res => res.data )
               .then( data => {
                  // console.log('ManageBioPhotos bio from DB BEFORE modification is ', data)
                  if( data.bioPhoto.length > 0 ){
                     setShowBioPhoto(true)
                  }
                  else{
                     setSnackbarOpen(true)
                     setTimeout( () => {
                           setSnackbarOpen(false)
                     }, 2500 )
                  }
                  setBio(data)
                  setBioPhoto(data.bioPhoto)

                  /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
                  after a GET was made to the Node server. */
                  axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
               } )
      }
   }, [fetchBio, userIdSelected] )

   /* Sent down to <MySelect /> which sends back a String containing the
   selected bio */
   const onChange = (e) => {
   /* This scans through the bioList and on a match returns the userId
      from the userIdLIst */
   for( var i = 0; i < bioList.length; i++ ){
      if( e.target.value === bioList[i] ){
         setUserIdSelected( userIdList[i] )
      }
   }
   }

   /* This is sent down to <GetBioList /> which sends back an alphabetical
      list of user information from tbl_user from MySQL. This information
      is saved in two arrays. The first array bioListForDropdown has the
      names to display in the dropdown <MySelect />, and the second array
      userIdFromDropdownhas the corresponding userId corresponding to the
      same index in bioListForDropdown. */
   const getBioListPartial = useCallback((bioListFromDB) => {

      /* Take the alphabetical bioListFromDB and piece together strings
         to display in the dropdown menu */
      const bioListForDropdown = bioListFromDB.map( ( bio, index ) => { 
         return bio.lastName + ", " + bio.givenFirstName + ", " + bio.militaryRank + " TPS Class " + bio.tpsClass
      } )

      /* Take the corresponding userId from each item in the bioListFromDB
         for retieval in the onChange function */
      const userIdFromDropdown = bioListFromDB.map( bio => { 
         return bio.userId
      } )

      setBioList(bioListForDropdown)
      setUserIdList(userIdFromDropdown)
   }, [] )

   /* Takes updated bio from <AddBioPhoto /> and puts it in local state */
   const addBioPhotoToLocalState = (bioWithPhotoAdded) => {
      if( bioWithPhotoAdded.bioPhoto.length > 0 ){ setShowBioPhoto(true) }
      setBio(bioWithPhotoAdded)
   }

   // Updates the Bio in MongoDB
   const updateBio = () => {
      axios.put(node_server + 'bio', {
         bio,
         CLIENT_PUBLIC_TOKEN
      })
         .then( res => res.data )
         .then( data => {
               setBio(data)
               setFetchBio( !fetchBio )
         })
   }

   // Sent to <ShowBioPhotoToManage /> to get id of photo to edit
   const getPhotoIdToEdit = (id) => {
      setPhotoIdToEdit(id)
      /* Operating inside a Hook */
      setPhotoToEdit(
         bioPhoto.filter( photo => {
               if(photo._id === id){
                  return photo
               }
               return null
         } )
      )
      /* This removes <ShowBioPhotoToManage while EDIT is being performed */
      setShowBioPhoto(false)
      /* This shows or hides <AddBioPhoto /> and <EditBioPhoto /> */
      setShowEditPhoto(true)
   }

   /* Send down to <EditBioPhoto />, will send back updated data */
   const updatedDataForDB = (d) => {
      /* Operating inside a Hook */
      setBioPhoto(
         bioPhoto.filter( photo => {
               if(photo._id === photoIdToEdit){
                  photo.bioPhotoCaption = d.caption
                  photo.bioPhotoFilename = d.filename
                  photo.bioPhotoWidth = d.bioPhotoWidth || photoDims.bioPhotoWidth
               }
               return null
         } )
      )
      updateBio()
      setShowEditPhoto(false)
      setShowBioPhoto(true)
   }

   // Sent to <ShowBioPhotoToManage /> in order to move a Bio Photo Up One Spot
   const movePhotoEntryUp = (index) => {
      const tempBioPhoto = bioPhoto[index - 1]
      bioPhoto[index - 1] = bioPhoto[index]
      bioPhoto[index] = tempBioPhoto

      updateBio()
   }

   // Sent to <ShowBioPhotoToManage /> in order to move a Bio Photo Down One Spot
   const movePhotoEntryDown = (index) => {
      const tempBioPhoto = bioPhoto[index + 1]
      bioPhoto[index + 1] = bioPhoto[index]
      bioPhoto[index] = tempBioPhoto

      updateBio()
   }

   const handleSnackBar = () => {
      setSnackBarOpen(true)
      setTimeout(() => {
     }, 2500)
   }

   return (
      <Box style={{marginTop: '6rem'}} >
         <Typography variant='h4' >
               Manage Bio Photos
         </Typography>

         <GetBioListPartial getBioListPartial={getBioListPartial} />

         <Grid  data-testid='select-a-bio-dropdown' >
            <MySelect label="Select A Bio" options={bioList} onChange={onChange} style={{marginTop: '1rem'}} />
         </Grid>
         {!showEditPhoto ?
               <AddBioPhoto addBioPhotoToLocalState={addBioPhotoToLocalState} bio={bio} />
         :   null}
         {snackbarOpen ?
         <Grid container item style={{marginTop: '2rem', marginLeft: '30%', width: '40%'}} >
               <Snackbar type='error' msg={'That Alumni member does not yet have any photos in the Database'} />
         </Grid>
         : null}
         {showBioPhoto ?
               <ShowBioPhotoToManage bio={bio} getPhotoIdToEdit={getPhotoIdToEdit} movePhotoEntryDown={movePhotoEntryDown} movePhotoEntryUp={movePhotoEntryUp} />
         :   null}
         {showEditPhoto ?
               <EditBioPhoto bioPhoto={bioPhoto} photoToEdit={photoToEdit} updatedDataForDB={updatedDataForDB} />
         :   null}
         {snackBarOpen
            ?
               <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                  <Grid item sm={6}>
                        <Snackbar msg={'Your photo was added!'} type={'success'} />
                  </Grid>
               </Grid>
            :
            null
         }
         <AddEditPhotoFieldsComponent
            handleSnackBar={handleSnackBar}
            title={'Add Photo Page'}
         />
      </Box>
   )
}

export default ManageBioPhotos