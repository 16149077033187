import React, { useCallback }             from 'react';
import                                    './Navbar.css'
import AppBar                             from '@material-ui/core/AppBar';
import {
   bioTblUserDataAction,
   goodLoginAction,
   hasBioAction,
   logoutAction,
   isAdminAction, 
   isAlumniAction,
   isSuperUserAction,
   userIdOfBioToEditAction
}                                         from "../Redux/Actions"
import Button                             from '@material-ui/core/Button';
import DoesUserHaveABioComponent          from './DoesUserHaveABioComponent'
import Hamburger                          from "./Hamburger"
import HamburgerSmallDisplay              from './HamburgerSmallDisplay';
import { Link }                           from "react-router-dom"
import { makeStyles }                     from '@material-ui/core/styles';
import { store }                          from "../Redux/Store"
import Toolbar                            from '@material-ui/core/Toolbar';
import Typography                         from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   menuButton: {
      marginRight: theme.spacing(2),
   },
   title: {
      flexGrow: 1,
   },
}));

const handleLogout = () => {
   store.dispatch(goodLoginAction(false))
   store.dispatch(isAdminAction(false))
   store.dispatch(isAlumniAction(false))
   store.dispatch(isSuperUserAction(false))
   store.dispatch(logoutAction())
   store.dispatch(userIdOfBioToEditAction(''))
   store.dispatch(bioTblUserDataAction({}))
}

export default function ButtonAppBar() {

   const classes = useStyles();

   const { goodLogin, showAlumniBioNavbarButton, showClassPagesNavbarButton, showHomeNavbarButton, signedInUserId } = store.getState()

    /*
     * This checks to see if the user has a bio, gets returned a TRUE / FALSE and sends hasBio to the store so an alum can Edit their own bio
     */
   const doesUserHaveABioReport = useCallback((hasABio) => {
      if(hasABio){
         store.dispatch(hasBioAction(true))
      }
      else{
         store.dispatch(hasBioAction(false))
      }
   }, [])

   return (
      <div className={classes.root}>
         <AppBar position="fixed" style={{marginBottom: '4rem'}}>
         <Toolbar>

               {goodLogin ? <DoesUserHaveABioComponent doesUserHaveABioReport={doesUserHaveABioReport} signedInUserId={signedInUserId} /> : null}

               <Link to="/" style={{ color: "inherit", textDecoration: "none", marginRight: '0.5rem' }}>
                  <Button id='home-button-navbar' color="inherit">
                     <Typography>
                        Home
                     </Typography>
                  </Button>
               </Link>

               <Link to="/BioList" style={{ color: "inherit", textDecoration: "none", marginRight: '0.5rem' }}>
                  <Button data-testid='navbar-alumni-bios-button' id='alumni-bios-button-navbar' color="inherit">
                     <Typography >
                        Alumni Bios
                     </Typography>
                  </Button>
               </Link>

               <Link to="/ClassPages" style={{ color: "inherit", textDecoration: "none" }}>
                  <Button data-testid='navbar-class-pages-button' id='class-pages-button-navbar' color="inherit">
                     <Typography>
                        Class Pages
                     </Typography>
                  </Button>
               </Link>
            
            {goodLogin && <Hamburger />}

            <Typography id='title' variant="h6" className={classes.title}>
               USAF Test Pilot School Alumni
            </Typography>

               <Link to="/PhotoCollections" style={{ color: "inherit", textDecoration: "none" }}>
                  <Button data-testid='navbar-photos-button' id='photos-button-navbar' color="inherit">
                     <Typography>
                        Photos
                     </Typography>
                  </Button>
               </Link>

               <Link to="/PhotoSearch" style={{ color: "inherit", textDecoration: "none" }}>
                  <Button data-testid='navbar-photo-search-button' id='photos-button-navbar' color="inherit">
                     <Typography>
                        Photo Search
                     </Typography>
                  </Button>
               </Link>

               <Link to="/YearbookPage" style={{ color: "inherit", textDecoration: "none" }}>
                  <Button data-testid='navbar-yearbook-button' id='yearbook-button-navbar' color="inherit">
                     <Typography>
                        Yearbook
                     </Typography>
                  </Button>
               </Link>
            
            {goodLogin ?
               <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                  <Button data-testid='navbar-logout-button' id='login-logout-button-navbar' color="inherit" onClick={handleLogout}>
                     <Typography>
                        Logout
                     </Typography>
                  </Button>
               </Link>
               :
               <Link to="/Login" style={{ color: "inherit", textDecoration: "none" }}>
                  <Button data-testid='navbar-login-button' id='login-logout-button-navbar' color="inherit">
                     <Typography>
                        Alumni Login
                     </Typography>
                  </Button>
               </Link>
            }

            <HamburgerSmallDisplay />
         </Toolbar>
         </AppBar>
      </div>
   )
}
