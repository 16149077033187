import React, { useEffect, useState }           from 'react'
import axios                                    from 'axios'
import Box                                      from '@material-ui/core/Box'
import Button                                   from '@material-ui/core/Button'
import Container                                from '@material-ui/core/Container'
import { CLIENT_PUBLIC_TOKEN, node_server }     from '../Helpers'
import GetClassLeaderAwardData                  from '../Data/GetClassLeaderAwardData'
import Grid                                     from '@material-ui/core/Grid'
import MySelect                                 from "../Components/MySelect"
import { store }                                from '../Redux/Store'
import TextField                                from '@material-ui/core/TextField'
import { tpsClassAction }                       from '../Redux/Actions'
import Typography                               from '@material-ui/core/Typography'

const ClassLeaderAwardDataPage = props => {

   const [ allGrads, setAllGrads ] = useState([])
   const [ classData, setClassData ] = useState({})
   const [ currentSearch, setCurrentSearch ] = useState('')
   const [ nameAbHonts, setNameAbHonts ] = useState('')
   const [ nameCDot, setNameCDot ] = useState('')
   const [ nameClassLeader, setNameClassLeader ] = useState('')
   const [ nameDistinguishedAlumnus, setNameDistinguishedAlumnus ] = useState('')
   const [ nameLiethenTittle, setNameLiethenTittle ] = useState('')
   const [ nameOnizukaPropWash, setNameOnizukaPropWash ] = useState('')
   const [ namePropWash, setNamePropWash ] = useState('')
   const [ nameOutstandingFTE, setNameOutstandingFTE ] = useState('')
   const [ nameRaymondJones, setNameRaymondJones ] = useState('')
   const [ newEntry, setNewEntry ] = useState(false)
   const [ searchResults, setSearchResults ] = useState([])
   const [ showDataEntry, setShowDataEntry ] = useState(false)
   const [ tpsClass, setTpsClass ] = useState('')
   const [ tpsClasses, setTpsClasses ] = useState([])
   const [ userIdAbHonts, setUserIdAbHonts ] = useState('')
   const [ userIdCDot, setUserIdCDot ] = useState('')
   const [ userIdClassLeader, setUserIdClassLeader ] = useState('')
   const [ userIdDistinguishedAlumnus, setUserIdDistinguishedAlumnus ] = useState('')
   const [ userIdLiethenTittle, setUserIdLiethenTittle ] = useState('')
   const [ userIdOnizukaPropWash, setUserIdOnizukaPropWash ] = useState('')
   const [ userIdPropWash, setUserIdPropWash ] = useState('')
   const [ userIdOutstandingFTE, setUserIdOutstandingFTE ] = useState('')
   const [ userIdRaymondJones, setUserIdRaymondJones ] = useState('')

   /* Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page */
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'classes')
         .then( res => res.data )
         .then( data => {
               setTpsClasses(data)
         })
    }, [] )

   /* Get all grads from tbl_auth for use in Search */
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'grad/getAllGradsFromTblAuth')
      .then( res => {
         setAllGrads(res.data)
      } )
   }, [] )

   /* Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store */
   const onChange = (event) => {
      setTpsClass(event.target.value)
      store.dispatch(tpsClassAction(event.target.value))
   }

   const handleSearch = (e) => {
      if (e.target.value.length > 2) {
         const results = allGrads.filter( (g) => {
         if (g.username.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
            return g
         }
         return null
         })
         setSearchResults(results)
      }
   }

   const handleSelectFromSearch = (g) => {
      let nameToSave = g.username.toLocaleLowerCase()
      const numDots = nameToSave.split('.')
      let firstName = ''
      let lastName = ''
      if (numDots.length === 3) {
         firstName = numDots[0][0].toLocaleUpperCase() + numDots[0].slice(1,numDots[0].length)
         lastName = numDots[1][0].toLocaleUpperCase() + numDots[1].slice(1,numDots[1].length)
      } else if (numDots.length === 4) {
         const firstInitial = numDots[0][0].toLocaleUpperCase() + '.'
         const secondInitial = numDots[1][0].toLocaleUpperCase() + '.'
         firstName = firstInitial + secondInitial
         lastName = numDots[2][0].toLocaleUpperCase() + numDots[2].slice(1,numDots[2].length)
      }
      switch (currentSearch) {
         case 'AB Honts':
         setUserIdAbHonts(g.userId)
         setNameAbHonts(firstName + ' ' + lastName)
         break
         case 'C-Dot':
         setUserIdCDot(g.userId)
         setNameCDot(firstName + ' ' + lastName)
         break;
         case 'Class Leader':
         setUserIdClassLeader(g.userId)
         setNameClassLeader(firstName + ' ' + lastName)
         break;
         case 'Liethen-Tittle':
         setUserIdLiethenTittle(g.userId)
         setNameLiethenTittle(firstName + ' ' + lastName)
         break;
         case 'Onizuka Prop Wash':
         setUserIdOnizukaPropWash(g.userId)
         setNameOnizukaPropWash(firstName + ' ' + lastName)
         break;
         case 'Prop Wash':
         setUserIdPropWash(g.userId)
         setNamePropWash(firstName + ' ' + lastName)
         break;
         case 'Raymond Jones':
         setUserIdRaymondJones(g.userId)
         setNameRaymondJones(firstName + ' ' + lastName)
         break;
         case 'Outstanding FTE':
         setUserIdOutstandingFTE(g.userId)
         setNameOutstandingFTE(firstName + ' ' + lastName)
         break;
         case 'Distinguished Alumnus':
         setUserIdDistinguishedAlumnus(g.userId)
         setNameDistinguishedAlumnus(firstName + ' ' + lastName)
         break;
      
         default:
         break
      }
   }

   /* GET Class Leader and Award Data for selected class from <GetClassLeaderAwardData /> Data Component */
   const getClassData = (d) => {

      setClassData(d.classData)

      if (d.classData !== null) {

         if (d.classData.abHonts !== undefined) {
         setNameAbHonts(d.classData.abHonts.abHontsName)
         }

         if (d.classData.cDot !== undefined) {
         setNameCDot(d.classData.cDot.cDotName)
         }

         if (d.classData.classLeader !== undefined) {
         setNameClassLeader(d.classData.classLeader.classLeaderName)
         }
         if (d.classData.distinguishedAlumnus !== undefined) {
         setNameDistinguishedAlumnus(d.classData.distinguishedAlumnus.distinguishedAlumnusName)
         }
         if (d.classData.liethenTittle !== undefined) {
         setNameLiethenTittle(d.classData.liethenTittle.liethenTittleName)
         }
         if (d.classData.onizukaPropWash !== undefined) {
         setNameOnizukaPropWash(d.classData.onizukaPropWash.onizukaPropWashName)
         }
         if (d.classData.propWash !== undefined) {
         setNamePropWash(d.classData.propWash.propWashName)
         }
         if (d.classData.outstandingFTE !== undefined) {
         setNameOutstandingFTE(d.classData.outstandingFTE.outstandingFTEName)
         }
         if (d.classData.raymondJones !== undefined) {
         setNameRaymondJones(d.classData.raymondJones.raymondJonesName)
         }
         if (d.classData.abHonts !== undefined) {
         setUserIdAbHonts(d.classData.abHonts.abHontsUserId)
         }
         if (d.classData.cDot !== undefined) {
         setUserIdCDot(d.classData.cDot.cDotUserId)
         }
         if (d.classData.classLeader !== undefined) {
         setUserIdClassLeader(d.classData.classLeader.classLeaderUserId)
         }
         if (d.classData.distinguishedAlumnus !== undefined) {
         setUserIdDistinguishedAlumnus(d.classData.distinguishedAlumnus.distinguishedAlumnusUserId)
         }
         if (d.classData.liethenTittle !== undefined) {
         setUserIdLiethenTittle(d.classData.liethenTittle.liethenTittleUserId)
         }
         if (d.classData.onizukaPropWash !== undefined) {
         setUserIdOnizukaPropWash(d.classData.onizukaPropWash.onizukaPropWashUserId)
         }
         if (d.classData.propWash !== undefined) {
         setUserIdPropWash(d.classData.propWash.propWashUserId)
         }
         if (d.classData.outstandingFTE !== undefined) {
         setUserIdOutstandingFTE(d.classData.outstandingFTE.outstandingFTEUserId)
         }
         if (d.classData.raymondJones !== undefined) {
         setUserIdRaymondJones(d.classData.raymondJones.raymondJonesUserId)
         }
      }

      if (d.classData === null) {
         setNewEntry(true)
      }

      setShowDataEntry(true)
   }

   const handleSubmit = () => {
      let dataToSubmit = {
         abHonts: {
         abHontsUserId: userIdAbHonts,
         abHontsName: nameAbHonts
         },
         cDot: {
         cDotUserId: userIdCDot,
         cDotName: nameCDot
         },
         classLeader: {
         classLeaderUserId: userIdClassLeader,
         classLeaderName: nameClassLeader
         },
         distinguishedAlumnus: {
         distinguishedAlumnusUserId: userIdDistinguishedAlumnus,
         distinguishedAlumnusName: nameDistinguishedAlumnus
         },
         liethenTittle: {
         liethenTittleUserId: userIdLiethenTittle,
         liethenTittleName: nameLiethenTittle
         },
         onizukaPropWash: {
         onizukaPropWashUserId: userIdOnizukaPropWash,
         onizukaPropWashName: nameOnizukaPropWash
         },
         outstandingFTE: {
         outstandingFTEUserId: userIdOutstandingFTE,
         outstandingFTEName: nameOutstandingFTE
         },
         propWash: {
         propWashUserId: userIdPropWash,
         propWashName: namePropWash
         },
         raymondJones: {
         raymondJonesUserId: userIdRaymondJones,
         raymondJonesName: nameRaymondJones
         },
         tpsClass
      }

      if (newEntry) {
         axios.post(node_server + 'classLeaderAwardData', dataToSubmit)
         .then( res => {
         setCurrentSearch('')
         setNameAbHonts('')
         setNameCDot('')
         setNameClassLeader('')
         setNameDistinguishedAlumnus('')
         setNameLiethenTittle('')
         setNameOnizukaPropWash('')
         setNamePropWash('')
         setNameOutstandingFTE('')
         setNameRaymondJones('')
         setNewEntry(false)
         setSearchResults([])
         setShowDataEntry(false)
         setTpsClass('')
         setUserIdAbHonts('')
         setUserIdCDot('')
         setUserIdClassLeader('')
         setUserIdDistinguishedAlumnus('')
         setUserIdLiethenTittle('')
         setUserIdOnizukaPropWash('')
         setUserIdPropWash('')
         setUserIdOutstandingFTE('')
         setUserIdRaymondJones('')
         } )
      } else {
         dataToSubmit._id = classData._id
         axios.put(node_server + `classLeaderAwardData/${classData._id}`, dataToSubmit)
         .then( res => {
            setCurrentSearch('')
            setNameAbHonts('')
            setNameCDot('')
            setNameClassLeader('')
            setNameDistinguishedAlumnus('')
            setNameLiethenTittle('')
            setNameOnizukaPropWash('')
            setNamePropWash('')
            setNameOutstandingFTE('')
            setNameRaymondJones('')
            setNewEntry(false)
            setSearchResults([])
            setShowDataEntry(false)
            setTpsClass('')
            setUserIdAbHonts('')
            setUserIdCDot('')
            setUserIdClassLeader('')
            setUserIdDistinguishedAlumnus('')
            setUserIdLiethenTittle('')
            setUserIdOnizukaPropWash('')
            setUserIdPropWash('')
            setUserIdOutstandingFTE('')
            setUserIdRaymondJones('')
         } )
      }

  }

   const searchListToDisplay = searchResults.map( (d, i) => {
      return (
         <Grid key={d.userId} item xs={12}>
         <Button onClick={() => handleSelectFromSearch({userId: d.userId, username: d.username})}>
            <Typography variant='subtitle1'>
               {d.username}
            </Typography>
         </Button>
         </Grid>
      )
   } )

   return (
      <Box>
         <GetClassLeaderAwardData getClassData={getClassData} tpsClass={tpsClass} />
         <Container>
         <Grid container justifyContent='center' style={{marginTop: '2rem'}}>
            <Grid data-testid='class-dropdown' container item xs={12} justifyContent='center' >
               <MySelect name="searchSelection" label="Select" options={tpsClasses} onChange={onChange} />
            </Grid>

            {showDataEntry ?
               <Grid container style={{marginTop: '2rem'}}>
               <Grid container>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Class Leader')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdClassLeader} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Class Leader' value={nameClassLeader} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
                  <Grid item xs={3} style={{marginLeft: '1rem', height: '250px', overflow: 'scroll', border: '2px solid darkgray', borderRadius: '0.5rem', padding: '1rem' }}>
                     {searchListToDisplay}
                  </Grid>
               </Grid>
               <Grid container style={{marginTop: '1rem'}}>
                  <Grid item xs={6}>
                     <Typography variant='h5'>
                     Current Awards
                     </Typography>
                  </Grid>
                  <Grid item xs={6}>
                     <Typography variant='h5'>
                     Previous Awards
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container style={{marginTop: '0.5rem'}}>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('C-Dot')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdCDot} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField name='cdot' label='Aaron "C-Dot" George' value={nameCDot} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
               </Grid>
               <Grid container style={{marginTop: '0.5rem'}}>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Liethen-Tittle')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdLiethenTittle} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Liethen-Tittle' value={nameLiethenTittle} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('AB Honts')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdAbHonts} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='A.B. Honts' value={nameAbHonts} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
               </Grid>
               <Grid container style={{marginTop: '0.5rem'}}>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Onizuka Prop Wash')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdOnizukaPropWash} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Onizuka Prop Wash' value={nameOnizukaPropWash} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Prop Wash')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdPropWash} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Prop Wash' value={namePropWash} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
               </Grid>
               <Grid container style={{marginTop: '0.5rem'}}>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Raymond Jones')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdRaymondJones} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Raymond L. Jones' value={nameRaymondJones} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Outstanding FTE')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdOutstandingFTE} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Outstanding FTE' value={nameOutstandingFTE} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
               </Grid>
               <Grid container style={{marginTop: '0.5rem'}}>
                  <Grid item xs={2}>
                     <TextField label='search' onFocus={() => {setCurrentSearch('Distinguished Alumnus')}} variant='outlined' onChange={handleSearch} />
                  </Grid>
                  <Grid item xs={1}>
                     <TextField label='userId' value={userIdDistinguishedAlumnus} variant='outlined' />
                  </Grid>
                  <Grid item xs={3} >
                     <TextField label='Distinguished Alumnus' value={nameDistinguishedAlumnus} variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} />
                  </Grid>
                  <Grid container item xs={1} alignContent='center'>
                     <Button color='primary' variant='contained' style={{marginLeft: '1rem'}} onClick={handleSubmit}>
                     Submit
                     </Button>
                  </Grid>
               </Grid>
               </Grid>
               :
               null
            }

         </Grid>
         </Container>
      </Box>
   )
}

export default ClassLeaderAwardDataPage
