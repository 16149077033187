import React                           from 'react';
import { Route, Switch }               from "react-router-dom"
import                                 './App.css';
import AddAStoryPage                   from './Pages/AddAStoryPage'
import AddGrad                         from "./Pages/AddGrad"
import AddNewClassPage                 from "./admin/Pages/AddNewClassPage"
import AddOneBioPage                   from './Pages/AddOneBioPage'
import AddPhoto                        from './Pages/AddPhoto'
import Analytics                       from './Pages/Analytics'
import AnnouncementLanding             from "./Pages/AnnouncementLanding"
import BioList                         from './Pages/BioList'
import Breitling                       from './Pages/Breitling'
import ClassPages                      from './Pages/ClassPages'
import CommandantBio                   from './Pages/CommandantBio'
import { createTheme }                 from '@material-ui/core/styles'
import DeputyCommandantBio             from './Pages/DeputyCommandantBio'
import GetGrads                        from "./Components/GetGrads"
import GetGradsByUserId                from './Components/GetGradsByUserId';
import EditABio                        from './Pages/EditABio'
import EditAProfile                    from './Pages/EditAProfile'
import EditOneAnnouncement             from "./Pages/EditOneAnnouncement"
import EditOneBioPage                  from './Pages/EditOneBioPage'
import EditOneProfilePage              from './Pages/EditOneProfilePage'
import ForgotUsernamePassword          from "./Pages/ForgotUsernamePassword"
import History                         from "./Pages/History"
import Landing                         from "./Pages/Landing"
import Login                           from "./Pages/Login"
import ManageBioPhotos                 from './Pages/ManageBioPhotos'
import ManageClassPatches              from './Pages/ManageClassPatches'
import ManageClassPhotos               from './Pages/ManageClassPhotos'
import ManagePhotoCollectionPhotos     from './Pages/ManagePhotoCollectionPhotos'
import ManagePhotos                    from './Pages/ManagePhotos'
import MoreInfo                        from './Pages/MoreInfo'
import Navbar                          from "./Components/Navbar"
import NewAnnouncement                 from "./Pages/NewAnnouncement"
import ResetPassword                   from "./Pages/ResetPassword"
import PhotoCollections                from './Pages/PhotoCollections'
import PhotoSearch                     from './Pages/PhotoSearch'
import PrivacyPolicy                   from './Pages/PrivacyPolicy'
import Search                          from "./Pages/Search"
import ShowAStoryPage                  from './Pages/ShowAStoryPage'
import ShowOneAnnouncement             from "./Pages/ShowOneAnnouncement"
import ShowOneBioPage                  from './Pages/ShowOneBioPage'
import ShowOneGrad                     from "./Pages/ShowOneGrad"
import SuperUserAddABio                from './Pages/SuperUserAddABio'
import SuperUserAddAStory              from './Pages/SuperUserAddAStory'
import SuperUserResetPassword          from "./Pages/SuperUserResetPassword"
import { ThemeProvider }               from '@material-ui/core/styles'
import UpdateGrad                      from "./Pages/UpdateGrad"
import YearbookAdminPage               from './Pages/YearbookAdminPage'
import YearbookDisplayPage             from './Pages/YearbookDisplayPage'
import YearbookPage                    from './Pages/YearbookPage'

// Comment out line below when not generating hashes
// import GenerateHashes from "./Pages/GenerateHashes"

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(34,28,110)'
    },
    textPrimary: {
      main: 'rgb(252,248,83)'
    }
    // secondary: purple,
    // main: purple,
    // success: purple
  }
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <Navbar />
      <Switch>
        <Route exact path="/">
          {/* For use with BatchUpdate.php and passwords.md */}
          {/* <GenerateHashes /> */}
          <Landing />
        </Route>

        <Route exact path='/AddAStoryPage' >
          <AddAStoryPage />
        </Route>

        <Route exact path="/AddGrad">
          <AddGrad />
        </Route>

        <Route exact path="/admin/AddNewClassPage">
          <AddNewClassPage />
        </Route>

        <Route exact path='/AddOneBioPage'>
          <AddOneBioPage />
        </Route>

        <Route exact path="/AddPhoto">
          <AddPhoto />
        </Route>

        <Route exact path='/Analytics' >
          <Analytics />
        </Route>

        <Route exact path="/AnnouncementLanding" >
          <AnnouncementLanding />
        </Route>

        <Route exact path='/BioList'>
          <BioList />
        </Route>

        <Route exact path='/Breitling'>
          <Breitling />
        </Route>

        <Route exact path='/ClassPages'>
          <ClassPages />
        </Route>

        <Route exact path='/CommandantBio'>
          <CommandantBio />
        </Route>

        <Route exact path='/DeputyCommandantBio'>
          <DeputyCommandantBio />
        </Route>

        <Route exact path='/EditABio'>
          <EditABio />
        </Route>

        <Route exact path='/EditAProfile'>
          <EditAProfile />
        </Route>

        <Route exact path="/EditOneAnnouncement" >
          <EditOneAnnouncement />
        </Route>

        <Route exact path='/EditOneBioPage'>
          <EditOneBioPage />
        </Route>

        <Route exact path='/EditOneProfilepage'>
          <EditOneProfilePage />
        </Route>

        <Route exact path="/ForgotUsernamePassword">
          <ForgotUsernamePassword />
        </Route>

        <Route exact path="/GetGrads">
          <GetGrads />
        </Route>

        <Route exact path="/GetGradsByUserId">
          <GetGradsByUserId />
        </Route>

        <Route exact path="/History">
          <History />
        </Route>

        <Route exact path="/Login">
          <Login />
        </Route>

        <Route exact path='/ManageBioPhotos' >
          <ManageBioPhotos />
        </Route>

        <Route exact path='/ManageClassPatches' >
          <ManageClassPatches />
        </Route>

        <Route exact path='/ManageClassPhotos' >
          <ManageClassPhotos />
        </Route>

        <Route exact path='/ManagePhotoCollectionPhotos' >
          <ManagePhotoCollectionPhotos />
        </Route>

        <Route exact path='/ManagePhotos' >
          <ManagePhotos />
        </Route>

        <Route exact path='/MoreInfo' >
          <MoreInfo />
        </Route>

        <Route exact path="/NewAnnouncement" >
          <NewAnnouncement />
        </Route>

        <Route exact path='/PhotoCollections'>
          <PhotoCollections />
        </Route>

        <Route exact path='/PhotoSearch'>
          <PhotoSearch />
        </Route>

        <Route exact path='/PrivacyPolicy'>
          <PrivacyPolicy />
        </Route>

        <Route exact path="/ResetPassword">
          <ResetPassword />
        </Route>

        <Route exact path="/Search">
          <Search />
        </Route>

        <Route exact path="/ShowAStoryPage">
          <ShowAStoryPage />
        </Route>

        <Route exact path="/ShowOneAnnouncement" >
          <ShowOneAnnouncement />
        </Route>

        <Route exact path='/ShowOneBioPage'>
          <ShowOneBioPage />
        </Route>

        <Route exact path="/ShowOneGrad">
          <ShowOneGrad />
        </Route>

        <Route exact path='/SuperUserAddABio' >
          <SuperUserAddABio />
        </Route>

        <Route exact path='/SuperUserAddAStory'>
          <SuperUserAddAStory />
        </Route>

        <Route exact path="/SuperUserResetPassword">
          <SuperUserResetPassword />
        </Route>

        <Route exact path="/UpdateGrad">
          <UpdateGrad />
        </Route>

        <Route exact path='/YearbookAdminPage'>
          <YearbookAdminPage />
        </Route>

        <Route exact path='/YearbookDisplayPage'>
          <YearbookDisplayPage />
        </Route>

        <Route exact path='/YearbookPage'>
          <YearbookPage />
        </Route>

      </Switch>
      </ThemeProvider>
    </div>
  );
}

export default App;
