/*
 * This is so a superUser can edit the profile of any grad
 * Need to get userId of Profile To Edit
 * <EditAProfile />
 *
 * Once a user is selected, that userId is sent to the store and there is a redirect to
 * <EditOneProfilePage />
 */
import React, { useCallback, useState }   from 'react'
import AllGrads                           from '../Components/AllGrads'
import BioDropdown                        from '../Components/BioDropdown'
import Box                                from '@material-ui/core/Box'
import Container                          from '@material-ui/core/Container'
import FormControl                        from '@material-ui/core/FormControl'
import Grid                               from '@material-ui/core/Grid'
import InputLabel                         from '@material-ui/core/InputLabel'
import { makeStyles }                     from '@material-ui/core/styles'
import MenuItem                           from '@material-ui/core/MenuItem'
import { Redirect }                       from 'react-router-dom'
import Select                             from '@material-ui/core/Select'
import { store }                          from '../Redux/Store'
import Typography                         from '@material-ui/core/Typography'
import { userIdOfProfileToEditAction }    from '../Redux/Actions'

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
   },
   selectEmpty: {
      marginTop: theme.spacing(2),
   },
}));

const EditAProfile = () => {

   const classes = useStyles();

   const [ profileList, setProfileList ] = useState([])
   const [ redirectToEditOneProfilePage, setRedirectToEditOneProfilePage ] = useState(false)
   const [ userIdOfProfileToEdit, setUserIdOfProfileToEdit ] = useState('')

   const getAllGrads = useCallback((gradsFromAllGrads) => {
      setProfileList(gradsFromAllGrads)
   }, [] )

   const getSelectedBio = (userId) => {
      setUserIdOfProfileToEdit(userId)
      store.dispatch(userIdOfProfileToEditAction(userId))
      setRedirectToEditOneProfilePage(true)
   }

   const menuItemsToDisplay = profileList.map( profile => {
      return(<MenuItem key={profile.userId} value={profile.userId}>
                  { profile.lastName + ', ' +  profile.givenFirstName + ', ' + profile.tpsClass }
               </MenuItem>)
   } )
    
   return (
      <Box style={{marginTop: '7rem'}}>
         { redirectToEditOneProfilePage ? <Redirect to='/EditOneProfilePage' /> : null }
         <AllGrads getAllGrads={getAllGrads} />
         <Container>
               <Grid container justifyContent='center'>
                  <Grid item xs={12}>
                     <Typography variant='h4'>
                           Edit a Profile (SuperUsers Only)
                     </Typography>
                  </Grid>
                  <Grid>
                     <BioDropdown bioList={profileList} getSelectedBio={getSelectedBio} />
                  </Grid>
               </Grid>
         </Container>
      </Box>
   )
}

export default EditAProfile
