/*
 * This is so a superUser can add More Info to an existing Bio or to a TPS Class
 * Need to get userId of Bio To Add To
 * <MoreInfo />
 *    <GetBioListPartial /> returns the following data on all MongoDB Bios:
 *       givenFirstName
 *       lastName
 *       militaryRank
 *       officialTitle (Bio Official Title)
 *       publicBio (Boolean)
 *       tpsClass
 *       userId (Number)
 *       _id of Bio
 * 
 *    getSelectedBio function returns the seleced userId to the Parent Component
 */
import React, { useCallback, useEffect, useState }                            from 'react'
import axios                                                                  from 'axios'
import BioDropdown                                                            from '../Components/BioDropdown'
import Button                                                                 from '@material-ui/core/Button'
import Box                                                                    from '@material-ui/core/Box'
import ClassDropdown                                                          from '../Components/ClassDropdown'
import Container                                                              from '@material-ui/core/Container'
import GetBioListPartial                                                      from '../Components/GetBioListPartial'
import { CLIENT_PUBLIC_TOKEN, getTPSClassesFromNodeServer, node_server }      from '../Helpers'
import Grid                                                                   from '@material-ui/core/Grid'
import MoreInfoClassGradDisplayForAdmin                                       from '../Components/MoreInfoClassGradDisplayForAdmin'
import MySelect                                                               from '../Components/MySelect'
import TextField                                                              from '@material-ui/core/TextField'
import Typography                                                             from '@material-ui/core/Typography'

const MoreInfo = () => {

      const [ arpsClass, setArpsClass ] = useState('')
      const [ bioList, setBioList ] = useState([])
      const [ title, setTitle ] = useState('')
      const [ itemTitle, setItemTitle ] = useState('')
      const [ mediaType, setMediaType ] = useState('')
      const [ moreInfoOnClass, setMoreInfoOnClass ] = useState({})
      const [ moreInfoOnGrad, setMoreInfoOnGrad ] = useState({})
      const [ tpsClass, setTpsClass ] = useState('')
      const [ tpsClasses, setTpsClasses ] = useState([])
      const [ urlFilename, setURLFilename ] = useState('')
      const [ userId, setUserId ] = useState('')

      const getBioListPartial = useCallback((bioListFromDB) => {
         setBioList(bioListFromDB)
      }, [] )

      // Calls the Node server and gets a list of all the TPS Classes from tbl_class_page
      useEffect( () => {
         getTPSClassesFromNodeServer()
         .then( res => {
            console.log('MoreInfo new api classes classes is', res)
            setTpsClasses(res)
         })
         .catch( msg => "There was an error on MoreInfo getTPSClassesFromNodeServer")
      }, [] )

      const getSelectedBio = (userId) => {
         setUserId(userId)
         getMoreInfoForGrad(userId)
      }

      /* 
      * Sent down to <ClassDropdown /> which sends back a String containing the selected TPS Class
      */
      const getSelectedClass = (e) => {
         setTpsClass(e)
         getMoreInfoForClass(e)
      }

      /*
      * GET More Info for selected Grad, if grad has no More Info then res.data.user[0] is undefined
      */
      const getMoreInfoForGrad = (bioUserId) => {
         axios.get(node_server + `moreInfo/${bioUserId}`)
               .then( res => {
                  if (res.data.user[0]) {
                     setMoreInfoOnGrad(res.data.user[0])
                  } else {
                     setMoreInfoOnGrad({})
                  }
               })
      }

      /*
      * GET More Info for selected Class, if grad has no More Info then res.data.user[0] is undefined
      */
      const getMoreInfoForClass = (e) => {
         axios.get(node_server + `moreInfo/${e}`)
         .then( res => {
               if (res.data.user[0]) {
                  setMoreInfoOnClass(res.data.user[0])
               } else {
                  setMoreInfoOnClass({})
               }
         })
      }

      const handleArpsClassChange = (e) => {
         setArpsClass(e.target.value)
      }

      const handleItemTitleChange = (e) => {
         setItemTitle(e.target.value)
      }

      const handleMediaTypeChange = (e) => {
         setMediaType(e.target.value)
      }

      const handleTitleChange = (e) => {
         setTitle(e.target.value)
      }

      const handleURLFilenameChange = (e) => {
         setURLFilename(e.target.value)
      }

      /* Object.keys( ).length === 0 && item dropdown !== '' means that dropdown was selected but no data for that item exists in the database

         Object.keys( ).length !== 0 means that item dropdown WAS selected and there is data for that item in the database

         In listed order below:
         - 1. Select One dropdown Only, No data in DB
         - 2. Select One dropdown Only, data in DB
         - 3. Select Both dropdowns, no data in DB for EITHER
         - 4. Select Both dropdowns, data in DB for BOTH
         - 5. Select Both dropdowns, data in DB for ONE       */
      const handleSubmit = () => {
         /* 1. Select One dropdown Only, No data in DB */
         if (
               ( ( tpsClass !== '' && Object.keys(moreInfoOnClass).length === 0 ) && ( userId   === '' ) ) ||
               ( ( userId   !== '' && Object.keys(moreInfoOnGrad).length  === 0 ) && ( tpsClass === '' ) )
            ) {
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
               axios.post(node_server + 'moreInfo', {
                  arpsClass,
                  title,
                  itemTitle,
                  mediaType,
                  tpsClass,
                  urlFilename,
                  userId
               })
               .then( res => {
                  axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
                  return 1
               })
         }
         /* 2. Select One dropdown Only, data in DB */
         else if (
               ( ( Object.keys(moreInfoOnClass).length > 0 ) && ( userId   === '' ) ) ||
               ( ( Object.keys(moreInfoOnGrad).length  > 0 ) && ( tpsClass === '' ) )
            ) {
               axios.put(node_server + 'moreInfo', {
                  arpsClass,
                  title,
                  itemTitle,
                  mediaType,
                  tpsClass,
                  urlFilename,
                  userId
               })
               .then( res => {
                  return 1
               })
         }
         /* 3. Select Both dropdowns, no data in DB for EITHER */
         else if ( tpsClass !== '' && Object.keys(moreInfoOnClass).length === 0 &&
                     userId   !== '' && Object.keys(moreInfoOnGrad).length  === 0
               ) {
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
               axios.post(node_server + 'moreInfo/both', {
                  arpsClass,
                  title,
                  itemTitle,
                  mediaType,
                  tpsClass,
                  urlFilename,
                  userId
               })
               .then( res => {
                  axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
                  return 1
               })
         }
         /* 4 Select Both dropdowns, data in DB for BOTH */
         else if (   Object.keys(moreInfoOnClass).length > 0 &&
                     Object.keys(moreInfoOnGrad).length > 0
                  ) {
               axios.put(node_server + 'moreInfo/both', {
                  arpsClass,
                  title,
                  itemTitle,
                  mediaType,
                  tpsClass,
                  urlFilename,
                  userId,
                  user_id: moreInfoOnGrad._id,
                  class_id: moreInfoOnClass._id
               })
               .then( res => {
                  return 1
               })
         }
         /* 4. Select Both dropdowns, data in DB for ONE */
         else {
               axios.post(node_server + 'moreInfo/figureItOut', {
                  arpsClass,
                  title,
                  itemTitle,
                  mediaType,
                  tpsClass,
                  urlFilename,
                  userId,
                  user_id: moreInfoOnGrad._id,
                  class_id: moreInfoOnClass._id
               })
               .then( res => {
                  return 1
               })
         }
      }




      return (
         <Box style={{marginTop: '7rem'}}>

         <GetBioListPartial getBioListPartial={getBioListPartial} />

         <Container>
            <Grid container justify='center'>
               <Grid item xs={12}>
                     <Typography variant='h4'>
                        More Info for Alum or TPS Class
                     </Typography>
               </Grid>
               
               <Grid container justifyContent='center' >
                  <Grid>
                     <BioDropdown bioList={bioList} getSelectedBio={getSelectedBio} />
                  </Grid>
                  
                  <Grid>
                     <ClassDropdown tpsClasses={tpsClasses} getSelectedClass={getSelectedClass} />
                  </Grid>

                  <Grid>
                     <TextField label='ARPS Class (unused)' variant='outlined' style={{marginTop: '0.5rem', marginLeft: '4.2rem'}} onChange={handleArpsClassChange} />
                  </Grid>

                  <Grid>
                     <MySelect label='Media Type' options={['PDF', 'YouTube', 'Webpage']} onChange={handleMediaTypeChange} />
                  </Grid>
               </Grid>

               <Grid container>
                  <Grid container>
                     {Object.keys(moreInfoOnClass).length > 0 && Object.keys(moreInfoOnGrad).length > 0 ?
                           <TextField label='Main Title' variant='outlined' style={{marginTop: '0.5rem', marginLeft: '4.2rem', width: '100%'}} disabled />
                           :
                           <TextField label='Main Title' variant='outlined' style={{marginTop: '0.5rem', marginLeft: '4.2rem', width: '100%'}} onChange={handleTitleChange} />
                     }
                  </Grid>  
               </Grid>

               <Grid container>
                  <Grid container>
                     <TextField label='Item Title' variant='outlined' style={{marginTop: '0.5rem', marginLeft: '4.2rem', width: '100%'}} onChange={handleItemTitleChange} />
                  </Grid>  
               </Grid>

               <Grid container>
                  <Grid container>
                     <TextField label='URL / PDF Filename' variant='outlined' style={{marginTop: '0.5rem', marginLeft: '4.2rem', width: '100%'}} onChange={handleURLFilenameChange} />
                  </Grid>  
               </Grid>

               <Grid container style={{marginTop: '1rem', marginLeft: '4.2rem'}} >
                  {   mediaType.length > 0 &&
                     itemTitle.length > 0 &&
                     (tpsClass !== '' || userId !== '') ?
                     <Button color='primary' variant='contained' onClick={handleSubmit} >
                           Submit
                     </Button>
                     :
                     <Button disabled variant='outlined'>
                           Submit
                     </Button>
                  }
               </Grid>

               <Grid container style={{marginTop: '2rem'}}>
                  <Grid container justifyContent='flex-start'>
                        {title.length > 0 ?
                           <Typography variant='body1'>
                              {title} Additional Information
                           </Typography>
                           : null
                        }
                  </Grid>
                  <Grid container justifyContent='flex-start' style={{marginLeft: '2rem'}}>
                        {itemTitle.length > 0 ?
                           <Typography variant='body1'>
                              {itemTitle}
                           </Typography>
                           : null
                        }
                  </Grid>
               </Grid>

               <MoreInfoClassGradDisplayForAdmin data={moreInfoOnGrad} type='Grad' />
               <MoreInfoClassGradDisplayForAdmin data={moreInfoOnClass} type='Class' />

            </Grid>
         </Container>
   </Box>
      )
}

export default MoreInfo
