/*
 * This is currently called by two Components, <ClassPages /> and <YearbookTPSClasses />
 */
import React from 'react'
import ClassDropdown from './ClassDropdown'
import { Grid, TextField, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const ClassSelectTwoWays = ({ getSelectedClass, handleClassTextEntry, tempTpsClass, tpsClasses }) => {
  return (
   <Grid container justifyContent='center'>
      <Grid container item xs={12} sm={2} alignItems='center' justifyContent='center'>
         <ClassDropdown tpsClasses={tpsClasses} getSelectedClass={getSelectedClass} />
      </Grid>

      <Grid container item xs={12} sm={1} alignItems='center' justifyContent='center'>
         <Typography variant='h6'>
            OR
         </Typography>
      </Grid>

      <Grid container item xs={12} sm={2} alignItems='center' justifyContent='center'>
         <TextField name='class-select-input' label='Enter 5 or 10 digit Class' value={tempTpsClass} variant='outlined' style={{width: '200px'}} onChange={handleClassTextEntry}/>
      </Grid>
   </Grid>
  )
}

ClassSelectTwoWays.propTypes = {
   getSelectedClass: PropTypes.func,
   handleClassTextEntry: PropTypes.func,
   tempTpsClass: PropTypes.string,
   tpsClasses: PropTypes.array
}

export default ClassSelectTwoWays