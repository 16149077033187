/*
 * Component on the <ManagePhotoCollectionPhotos /> Page
 */
import React, { useState }    from 'react'
import FormControl            from '@material-ui/core/FormControl'
import Grid                   from '@material-ui/core/Grid'
import InputLabel             from '@material-ui/core/InputLabel'
import MenuItem               from '@material-ui/core/MenuItem'
import PropTypes              from 'prop-types'
import Select                 from '@material-ui/core/Select'
import Typography             from '@material-ui/core/Typography'

const ViewPhotoCollection = ({collectionNames, getSelectedCollection}) => {

   const [ selection, setSelection ] = useState('')

   const menuItems = collectionNames.map((name) => <MenuItem key={name.photoCollectionName} value={name.photoCollectionName} >{name.photoCollectionName}</MenuItem>)

   const onChange = (e) => {
      const collectionName = e.target.value

      setSelection(collectionName)
      getSelectedCollection(collectionName)
   }

   return (
      <Grid>
         <Grid item xs={12}>
               <Typography variant='h6'>
                  View Collection
               </Typography>
         </Grid>
         <Grid data-testid='photo-collection-dropdown' item xs={12}>
               <FormControl style={{width: '60%'}}>
                  <InputLabel style={{marginLeft: '1rem'}}>Select a Collection</InputLabel>
                  <Select
                     onChange={onChange}
                     value={selection}
                     variant='outlined'
                  >
                     {menuItems}
                  </Select>
               </FormControl>
         </Grid>
      </Grid>
   )
}

ViewPhotoCollection.propTypes = {
   collectionNames: PropTypes.array,
   getSelectedCollection: PropTypes.func
}

export default ViewPhotoCollection
