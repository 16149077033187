/*
 * This Component is on the <PersonalAndClassStates /> Component
 * The <PersonalAndClassStats /> is on the SearchByName Component
 */

import React                  from 'react'
import Button                 from '@material-ui/core/Button'
import Container              from '@material-ui/core/Container'
import Grid                   from '@material-ui/core/Grid'
import PropTypes              from 'prop-types'
import { store }              from '../../Redux/Store'
import TextField              from '@material-ui/core/TextField'
import Typography             from '@material-ui/core/Typography'

const RaymondJones = ({ editClick, editItem, handleFocus, handleSearch }) => {

   const { classData } = store.getState()

   const editLocalClick = (e) => {
      editClick(e)
   }

   const handleLocalSearch = (e) => {
      handleSearch(e)
      handleFocus()
   }

   return (

   <Container >
      <Grid container >
         <Grid item xs={12} sm={5}>
            <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
               R. L. Jones
            </Typography>
         </Grid>
         {classData.raymondJones
            ?
               <Grid item xs={12} sm={6}>
                  <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
                     {classData.raymondJones.raymondJonesName}
                     <Button data-testid='rl-jones-edit-button' onClick={() => editLocalClick({ editItem: 'raymondJones' })} style={{marginLeft: '2rem'}}>
                        Edit
                     </Button>
                  </Typography>
               </Grid>
            :
               <Grid item xs={12} sm={6}>
                  <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
                     Unknown
                     <Button onClick={() => editLocalClick({ editItem: 'raymondJones' })} style={{marginLeft: '2rem'}}>
                        Edit
                     </Button>
                  </Typography>
               </Grid>
         }
         {editItem === 'raymondJones'
            ?
               <Grid item xs={7}>
                     <TextField
                        name='search-for-rl-jones-input'
                        label='Search for R.L. Jones'
                        onFocus={handleLocalSearch}
                        onChange={handleLocalSearch}
                        style={{width: '100%', marginLeft: '0.3rem'}}
                        variant='outlined'
                     />
               </Grid>
            :
               null
         }
      </Grid>
   </Container>
   )
}

RaymondJones.propTypes = {
   editClick: PropTypes.func,
   editItem: PropTypes.string,
   handleFocus: PropTypes.func,
   handleSearch: PropTypes.func
 }

export default RaymondJones
