import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Typography from '@material-ui/core/Typography'

const PrivacyPolicy = props => {

   const [ text, setText ] = useState([])

   // Get Text from DB
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'text/PrivacyPolicy')
            .then( res => res.data )
            .then( data => {
               console.log('PrivacyPolicy data is', data)
               setText(data.pageText)
               /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
                  after a GET was made to the Node server. */
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
            })
   }, [] )

   // useEffect( () => {
   //    axios.post(node_server + 'text/PrivacyPolicy', {
   //       CLIENT_PUBLIC_TOKEN,
   //       pageName: 'PrivacyPolicy',
   //       text: 'USAF TPS Alumni Site Privacy Policy'
   //    })
   //          .then( res => res.data )
   //          .then( data => {
   //             console.log('PrivacyPolicy data is', data)
   //          })
   // }, [] )

   const textToDisplay = text.map((t, i) => {
      // i is sent to inhibit the first announcement Divider, OTW there are two dividers under the Header
      if (i > 0) {
         return (
            <Grid container justifyContent='flex-start' key={i} >
               <Typography data-testid={`privacy-text-${i}`} variant='h6' paragraph align='left' >
                  - { t }
               </Typography>
            </Grid>
         )
      }
      else return null
   })

   return (
      <Box>
      <Container >

         <Grid container justifyContent='center' style={{marginTop: '6rem', marginBottom: '2rem'}}>
            <Typography variant='h4'>
            { text[0] }
            </Typography>
         </Grid>

         { textToDisplay }

      </Container>
      </Box>
   )
}

export default PrivacyPolicy
