/*
 * This is so a superUser can edit the bio of any grad
 * Need to get userId of Bio To Edit
 * <EditABio />
 *      <GetBioDataForMongoDB />
 * 
 *      <GetBioList /> was replaced by <GetBioListPartial />
 */
import React, { useCallback, useState }   from 'react'
import BioDropdown                        from '../Components/BioDropdown'
import { Container, Box, Grid }           from '@material-ui/core/'
import { userIdOfBioToEditAction }        from '../Redux/Actions'
import GetBioListPartial                  from '../Components/GetBioListPartial'
import { Redirect }                       from 'react-router-dom'
import SearchArray                        from '../Components/SearchArray'
import { store }                          from '../Redux/Store'
import Typography                         from '@material-ui/core/Typography'

const EditABio = () => {

    const [ bioList, setBioList ] = useState([])
    const [ redirectToEditOneBioPage, setRedirectToEditOneBioPage ] = useState(false)

    const getBioListPartial = useCallback((bioListFromDB) => {
        setBioList(bioListFromDB)
    }, [] )

    const getBiosToShow = (t) => {
        /* I don't think this actually gets used anymore */
    }

    const getSelectedBio = (userId) => {
// console.log('EditABio', userId)
        store.dispatch( userIdOfBioToEditAction(userId) )
        setRedirectToEditOneBioPage(true)
    }

    // const handleSelectFromSearch = (g) => {
        // let nameToSave = g.username.toLocaleLowerCase()
        // const numDots = nameToSave.split('.')
        // let firstName = ''
        // let lastName = ''
        // if (numDots.length === 3) {
        //   firstName = numDots[0][0].toLocaleUpperCase() + numDots[0].slice(1,numDots[0].length)
        //   lastName = numDots[1][0].toLocaleUpperCase() + numDots[1].slice(1,numDots[1].length)
        // } else if (numDots.length === 4) {
        //   const firstInitial = numDots[0][0].toLocaleUpperCase() + '.'
        //   const secondInitial = numDots[1][0].toLocaleUpperCase() + '.'
        //   firstName = firstInitial + secondInitial
        //   lastName = numDots[2][0].toLocaleUpperCase() + numDots[2].slice(1,numDots[2].length)
        // }

        // setDataToSubmit({...dataToSubmit, id: g.userId, name: firstName + ' ' + lastName})

        /* This shows the single alum selected from the search list */
        // setSearchResults([g])
    //  }

    // const searchListToDisplay = bioList.filter( (d, i) => {
    //     if (
    //         d.givenFirstName.includes(searchText) ||
    //         d.lastName.includes(searchText) ||
    //         d.tpsClass.includes(searchText)
    //     ) {
    //         return (
    //             <Grid key={d.userId} item xs={12}>
    //               <Button onClick={() => getSelectedBio(d.userId)}>
    //                 <Typography variant='subtitle1'>
    //                   {d.givenFirstName} {d.lastName} {d.tpsClass}
    //                 </Typography>
    //               </Button>
    //             </Grid>
    //           )
    //     }
    //     return null
    //  } )

    return (
        <Box style={{marginTop: '7rem'}}>
            { redirectToEditOneBioPage ? <Redirect to='/EditOneBioPage' /> : null }

            <GetBioListPartial getBioListPartial={getBioListPartial} />

            <Container>
                <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                        <Typography variant='h4'>
                            Edit a Bio (SuperUsers Only)
                        </Typography>
                    </Grid>

                    <BioDropdown bioList={bioList} getSelectedBio={getSelectedBio} />
                    <SearchArray getStuffToShow={getBiosToShow} list={bioList}/>


                </Grid>
            </Container>
        </Box>
    )
}

export default EditABio
