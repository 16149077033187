import React                  from 'react'
import Box                    from '@material-ui/core/Box'
import Container              from '@material-ui/core/Container'
import FormControlLabel       from '@material-ui/core/FormControlLabel'
import Grid                   from '@material-ui/core/Grid'
import PropTypes              from 'prop-types'
import Radio                  from '@material-ui/core/Radio'
import RadioGroup             from '@material-ui/core/RadioGroup'

const YearbookOptions = ({getOption}) => {

   const handleAddNewPageClick = (o) => {
      getOption(o)
   }

   return (
      <Box>
         <Container>
            <Grid container justifyContent='center' style={{marginTop: '2rem'}}>
               <RadioGroup row>

                  <FormControlLabel
                     control={<Radio color='primary' />}
                     label='Add New Yearbook Page'
                     labelPlacement='top'
                     onClick={() => handleAddNewPageClick('Add New Yearbook Page')}
                     value='addNewYearbookPage'
                  />

                  <FormControlLabel
                     data-testid='yearbook-options-radio'
                     name='yearbook-options-radio'
                     control={<Radio color='primary' />}
                     label='Class Leader / Award Data'
                     labelPlacement='top'
                     onClick={() => handleAddNewPageClick('Class Leader / Award Data')}
                     value='classLeaderAwardData'
                  />

                  <FormControlLabel
                     control={<Radio color='primary' />}
                     label='See Current Pages'
                     labelPlacement='top'
                     onClick={() => handleAddNewPageClick('See Current Pages')}
                     value='seeCurrentYearbookPages'
                  />

               </RadioGroup>
            </Grid>
         </Container>
      </Box>
   )
}

YearbookOptions.propTypes = {
  getOption: PropTypes.func
}

export default YearbookOptions
