import React, { useEffect, useState }              from 'react'
import axios                                       from 'axios'
import { Button, Grid, Typography }                from '@material-ui/core'
import { CLIENT_PUBLIC_TOKEN, node_server }        from '../Helpers'
import { Redirect }                                from "react-router-dom" /* Link was added for the Breitling Page */

const LandingPageCenterSection = props => {

   const [ commandantName, setCommandantName ] = useState('')
   const [ deputyCommandantName, setDeputyCommandantName ] = useState('')
   const [ showDeputyCommandantBio, setShowDeputyCommandantBio ] = useState(false)
   const [ showCommandantBio, setShowCommandantBio ] = useState(false)
   const [ showPrivacyPolicy, setShowPivacyPolicy ] = useState(false)

   const handleDeputyCommandantBioClick = () => {
      setShowDeputyCommandantBio(true)
   }

   const handleCommandantBioClick = () => {
      setShowCommandantBio(true)
   }

   const handleShowPrivacyPolicy = () => {
      setShowPivacyPolicy(!showPrivacyPolicy)
   }

   // Get Text from DB
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'text/CommandantAndDeputyName')
            .then( res => res.data )
            .then( data => {
               setCommandantName(data.CommandantName)
               setDeputyCommandantName(data.DeputyCommandantName)
               /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
                  after a GET was made to the Node server. */
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
            })
   }, [] )

   return (
      <Grid container item md={4}>
         {showCommandantBio ? <Redirect to="/CommandantBio" /> : null}
         {showDeputyCommandantBio ? <Redirect to="/DeputyCommandantBio" /> : null}
         {showPrivacyPolicy ? <Redirect to='/PrivacyPolicy' /> : null}
         <Grid item sm={12} style={{ marginTop: "5rem" }}>
               <img
                  className="tpsGradPatch"
                  alt="TPS Patch"
                  src='https://tps-alumni-aws-s3-bucket.s3.us-east-2.amazonaws.com/Patch%2C+TPS%2C+Grad%2C+Std+v2017+-+tinyjpg.c37532e2.jpeg'
               />
         </Grid>

         <Grid className='bio-div' container style={{marginLeft: '1rem', marginTop: '2rem'}}>
               <Grid container item justifyContent='space-between' alignContent='center' sm={10}>
                  <Typography variant='h6'>
                     Commandant: {commandantName}
                  </Typography>
               </Grid>
               <Grid container item justifyContent='space-between' alignContent='center' sm={1}>
                  <Button data-testid="commandant-bio-button" variant='outlined' onClick={handleCommandantBioClick}>
                     Bio
                  </Button>
               </Grid>
         </Grid>

         <Grid className='bio-div' container style={{marginLeft: '1rem'}}>
               <Grid container item justifyContent='space-between' alignContent='center' sm={10}>
                  <Typography variant='h6'>
                     Deputy Commandant: {deputyCommandantName}
                  </Typography>
               </Grid>
               <Grid container item justifyContent='space-between' alignContent='center' sm={1}>
                  <Button data-testid="deputy-commandant-bio-button" variant='outlined'  onClick={handleDeputyCommandantBioClick}>
                     Bio
                  </Button>
               </Grid>
         </Grid>

         <Grid item sm={12} >
               <Button data-testid="privacy-policy-button" variant='outlined' onClick={handleShowPrivacyPolicy}>
                  <Typography variant='subtitle2'>
                     Privacy Policy
                  </Typography>
               </Button>
         </Grid>
      </Grid>
   )
}

export default LandingPageCenterSection