
export const milStatusOptions =
[
   "AD",
   "Ret",
   "Guard",
   "Reserves",
   "Gov't Civilian",
   "Deceased",
]

export const milRankOptions =
[
   'Civ',
   'Sgt',
   'TSgt',
   'MSgt',
   'SMSgt',
   'CMSgt',
   '1st Lt',
   'Capt',
   'Maj',
   'Lt Col',
   'Col',
   'Brig Gen',
   'Maj Gen',
   'Lt Gen',
   'Gen',
   'LTJG',
   'LT',
   'LCDR',
   'CDR',
   'CAPT',
   '1stLt',
   'LtCol',
   'Air Marshall',
   'CW',
]

export const options =
[
   // 'Astronaut',
   'City',
   // 'Class',
   // 'Commandant',
   'Current Company',
   // 'Current Job Title',
   'Education',
   // 'General Officer',
   // 'Get All Grads',
   'State'
]

export const photoDims = {
   bioPhotoWidth: '80%'
}

export const photoSearchOptions = [
   'Search Photo Captions',
   'Search by Photo Tag',
]

export const superUserPhotoSearchOptions = [
   'Search Photo Captions',
   'Search by Photo Tag',
   'Show Photos that need Approval',
]

export const tpsCourses = {
   ftc: 'Flight Test Course',
   stc: 'Space Test Course',
}

export const trackOptions =
[
   'Pilot',
   'RPA Pilot',
   'FTE',
   'CSO',
   'STC',
]
