/*
 * This is the Top level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowOneClassPatch />
 *          <ShowGradsFromOneClass />
 *          <ShowClassPhoto />
 *          <ShowJournalEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddJournalEntry />
 *          <EditJournalEntry />
 */
import React, { useEffect, useState }                                   from 'react'
import ClassSelectTwoWays                                               from '../Components/ClassSelectTwoWays'
import { Grid }                                                         from '@material-ui/core'
import { getTPSClassesFromNodeServer }                                  from '../Helpers'
import ShowOneClassPage                                                 from '../Components/ShowOneClassPage'
import { store }                                                        from '../Redux/Store'
import { tpsClassAction, userIdAuthorizedToCrudWeeklyUpdateAction }     from '../Redux/Actions'

const ClassPages = props => {

   const [ tempTpsClass, setTempTpsClass ] = useState('')
   const [ tpsClasses, setTpsClasses ] = useState([])
   const [ userIdAuthorizedToCrudWeeklyUpdateArray, setUserIdAuthorizedToCrudWeeklyUpdate ] = useState(0)

   const { tpsClass } = store.getState()

   /* Cleanup function takes the TPS Class from the logged in users classData
   * and ensures the TPS Class for the logged in user gets added back to the
   * Store in case other TPS Classes were searched on this page.
   */
   useEffect( () => {
      return function cleanup() {
         const { classData } = store.getState()
         store.dispatch( tpsClassAction( classData.tpsClass ) )
      }
   }, [] )

   /* This used to be getTPSClasses() and used to call the PHP server. It is now 
      getTPSClassesFromNodeServer() and calls the Nover server and gets a list of all the TPS Classes from tbl_class_page
   */
   useEffect( () => {
      getTPSClassesFromNodeServer()
         .then( classes => {
               setTpsClasses(classes)
               setUserIdAuthorizedToCrudWeeklyUpdate( classes )
         } )
         .catch( msg => console.log('There was an error on get', msg) )
   }, [] )

   // Puts tpsClass from <ClassDropdown /> into a useState Hook, and sends the currently selected class to the Store
   const getSelectedClass = (e) => {
      setTempTpsClass('')
      store.dispatch( tpsClassAction(e) )
      submitTpsClass(e)
   }

   const handleClassTextEntry = (e) => {
      setTempTpsClass(e.target.value.toLocaleLowerCase())
   }

   useEffect( () => {
         if (tempTpsClass.length === 5 || tempTpsClass.length === 6) {
         store.dispatch( tpsClassAction(tempTpsClass) )
         submitTpsClass(tempTpsClass)
      }
   }, [tempTpsClass])

   const submitTpsClass = (c) => {
      // Find the index of event.target.value in order to know which userIdAuthorizedToCrudWeeklyUpdate
      // to select from userIdAuthorizedToCrudWeeklyUpdateArray and send that to the store
         store.dispatch(tpsClassAction(c))
         const tpsClassIndex = tpsClasses.findIndex( s => s === c )
         store.dispatch(userIdAuthorizedToCrudWeeklyUpdateAction(userIdAuthorizedToCrudWeeklyUpdateArray[tpsClassIndex]))
   }

   return (
      <Grid container justifyContent='center' style={{marginTop: '5rem', marginBottom: '5rem'}}>
         
         <ClassSelectTwoWays getSelectedClass={getSelectedClass} handleClassTextEntry={handleClassTextEntry} tempTpsClass={tempTpsClass} tpsClasses={tpsClasses} />

         {tpsClass !== ''
            ?
               <Grid container item xs={12} justifyContent='center'>
                  {/* tpsClass && was added to short circuit the fact that tpsClass might initially be undefined which breaks shit */}
                  {tpsClass && (tpsClass.length === 5 || tpsClass.length === 6) ? <ShowOneClassPage tpsClass={tpsClass} /> : null}
               </Grid>
            :
               null
         }

      </Grid>
   )
}

export default ClassPages