/* This is the top level Page that has four Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 * 
 *          <MySelect />
 *          <AddBioPhoto />
 *          <ShowBioPhotoToManage />
 * 
 *      <ManageClassPatches />
 *          <MySelect />
 *          <AddClassPatch />
 *          <Snackbar />
 *          <ShowClassPatchesToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 * 
 *      <ManagePhotoCollectionPhotos />
*/

import React, { useState }                   from 'react'
import Button                                from '@material-ui/core/Button'
import Grid                                  from '@material-ui/core/Grid'
import { Redirect }                          from 'react-router-dom'
import Typography                            from '@material-ui/core/Typography'

const ManagePhotos = () => {

    const [ redirectToBioPage,               setRedirectToBioPage             ] = useState(false)
    const [ redirectToClassPage,             setRedirectToClassPage           ] = useState(false)
    const [ redirectToPhotoCollectionPage,   setRedirectToPhotoCollectionPage ] = useState(false)
    const [ redirectToPatchPage,             setRedirectToPatchPage           ] = useState(false)

    const handleBioClick = () => {
        setRedirectToBioPage(true)
    }

    const handleClassClick = () => {
        setRedirectToClassPage(true)
    }

    const handlePatchClick = () => {
        setRedirectToPatchPage(true)
    }

    const handlePhotoCollectionClick = () => {
        setRedirectToPhotoCollectionPage(true)
    }

    return (
        <Grid style={{marginTop: '10rem'}}>
            {redirectToBioPage               ? <Redirect to='/ManageBioPhotos'               /> : null}
            {redirectToClassPage             ? <Redirect to='/ManageClassPhotos'             /> : null}
            {redirectToPatchPage             ? <Redirect to='/ManageClassPatches'            /> : null}
            {redirectToPhotoCollectionPage   ? <Redirect to='/ManagePhotoCollectionPhotos'   /> : null}

                <Grid>
                    <Typography variant='h3' style={{marginBottom: '5rem'}} >
                        Manage Photos
                    </Typography>
                </Grid>

                <Grid container direction='row' justifyContent='center'>

                    <Grid  style={{width: '20%', border: '1px solid black', borderRadius: '0.5rem', backgroundColor: 'rgba(211,211,211,0.5)'}}>
                        <Typography variant='h4' style={{marginTop: '1rem'}} >
                            Bio Pictures
                        </Typography>
                        <img
                                style={{width: '75%', borderRadius: '0.5rem', marginTop: '1rem', marginBottom: '1rem'}}
                                alt="Bio"
                                src='/images/gary.aldrich.1982a.hero2.jpg'
                        />
                        <Button data-testid='manage-bio-photos' variant="contained" color="primary" style={{marginBottom: '1rem'}} onClick={handleBioClick} > Add / Edit </Button>
                    </Grid>

                    <Grid  style={{width: '20%', border: '1px solid black', borderRadius: '0.5rem', backgroundColor: 'rgba(0,0,0,0)', marginLeft: '2rem', marginRight: '2rem'}}>
                        <Typography variant='h4' style={{marginTop: '1rem'}} >
                            Class Patches
                        </Typography>
                        <img
                                style={{width: '75%', borderRadius: '0.5rem', marginTop: '1rem', marginBottom: '1rem'}}
                                alt="Class"
                                src='https://tps-alumni-aws-s3-bucket.s3.us-east-2.amazonaws.com/class+patch+2002a-small.jpeg'
                        />
                        <Button data-testid='manage-class-patches' variant="contained" color="primary" style={{marginBottom: '1rem'}} onClick={handlePatchClick} > Add / Edit </Button>
                    </Grid>

                    <Grid  style={{width: '20%', border: '1px solid black', borderRadius: '0.5rem', backgroundColor: 'rgba(211,211,211,0.5)', marginRight: '2rem'}}>
                        <Typography variant='h4' style={{marginTop: '1rem'}} >
                            Class Pictures
                        </Typography>
                        <img
                                style={{width: '75%', borderRadius: '0.5rem', marginTop: '1rem', marginBottom: '1rem'}}
                                alt="Class"
                                src='https://tps-alumni-aws-s3-bucket.s3.us-east-2.amazonaws.com/class+photo+1960b-small.png'
                        />
                        <Button data-testid='manage-class-photos' variant="contained" color="primary" style={{marginBottom: '1rem'}} onClick={handleClassClick} > Add / Edit </Button>
                    </Grid>

                    <Grid  style={{width: '20%', border: '1px solid black', borderRadius: '0.5rem', backgroundColor: 'rgba(211,211,211,0.5)'}}>
                        <Typography variant='h4' style={{marginTop: '1rem'}} >
                            Photo Collections
                        </Typography>
                        <img
                                style={{width: '75%', borderRadius: '0.5rem', marginTop: '1rem', marginBottom: '1rem'}}
                                alt="Collections"
                                src='/images/wendall.shawler.1956a.diploma.jpg'
                        />
                        <Button data-testid='manage-photo-collections' variant="contained" color="primary" style={{marginBottom: '1rem'}} onClick={handlePhotoCollectionClick} > Add / Edit </Button>
                    </Grid>



                </Grid>
        </Grid>
    )
}

export default ManagePhotos